import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SubscriptionPricingComponent } from './subscription-pricing/subscription-pricing.component';

@Component({
  selector: 'inf-subscriptions-upgrade',
  standalone: true,
  imports: [CommonModule, SubscriptionPricingComponent],
  templateUrl: './subscriptions-upgrade.component.html',
})
export class SubscriptionsUpgradeComponent { }
