import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonModule } from 'primeng/button';
import { RippleModule } from 'primeng/ripple';
import { StyleClassModule } from 'primeng/styleclass';
import { DividerModule } from 'primeng/divider';
import { InputTextModule } from 'primeng/inputtext';
import { RouterModule } from '@angular/router';
import { NgOptimizedImage } from '@angular/common';


@Component({
  templateUrl: './footer.component.html',
  selector: 'inf-landing-footer',
  standalone: true,
  imports: [
    ButtonModule,
    CommonModule,
    DividerModule,  
    InputTextModule,
    NgOptimizedImage,
    RippleModule,
    RouterModule,
    StyleClassModule
  ],
})
export class LandingFooterComponent {

  onClickScroll(elementId: string): void {
    console.log(elementId);
    document.getElementById(elementId)?.scrollIntoView({behavior: "smooth"});
  }
}
