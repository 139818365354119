<div class="card flex flex-column" *ngIf="configurators">
  <div class="">
    <div class="flex align-items-center justify-content-between mb-3">
      <p class="label-normal font-bold">CONFIGURATORS: <span class="label-normal font-regular">{{ totalCount }}
          configurators</span></p>
      <!-- {{configurators.length}}  -->
      <p-button icon="pi pi-arrow-right" iconPos="right" label="See all" [routerLink]="['/configurators']"
        class="primary" styleClass="p-button-text p-button-sm"></p-button>
    </div>
    <p-divider></p-divider>
    <ng-container *ngFor="let configurator of configuratorsWithPublished; let i = index; trackBy: configTrackBy">
      <inf-configurators-list [configurator]="configurator[0]"
        [publishedConfigurator]="configurator[1]"></inf-configurators-list>
    </ng-container>
  </div>
</div>