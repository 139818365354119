import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { SearchSystemComponent } from '../search-system/search-system.component';
import { AlertService, DocumentSummary } from '@harmanpa/ng-cae';
import { TooltipModule } from 'primeng/tooltip';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { OverlayPanel, OverlayPanelModule } from 'primeng/overlaypanel';
import { HttpClient } from '@angular/common/http';
import { DefaultService } from 'generated-src';
import { ToolbarBatchCategoriesComponent } from './toolbar-batch-categories/toolbar-batch-categories.component';
import { ToolbarBatchFunctionsComponent } from './toolbar-batch-functions/toolbar-batch-functions.component';

@Component({
  selector: 'inf-toolbar-batch-editor',
  standalone: true,
  imports: [
    SearchSystemComponent,
    CommonModule,
    FormsModule,
    ButtonModule,
    TooltipModule,
    InputTextModule,
    OverlayPanelModule,
    ToolbarBatchFunctionsComponent,
    ToolbarBatchCategoriesComponent,
  ],
  templateUrl: './toolbar-batch-editor.component.html',
})
export class ToolbarBatchEditorComponent implements OnInit {
  /** All products */
  @Input() products: DocumentSummary[] = [];
  /** Selected products */
  @Input() selectedProducts: DocumentSummary[] = [];
  /** Boolean to know if its to show the batch actions or only the search bar */
  @Input() showBatchAction: boolean = true;
  /** On products search change  */
  @Output() searchChange = new EventEmitter<DocumentSummary[]>();
  /** On edit product */
  @Output() editProducts = new EventEmitter<void>();
  /** On delete product */
  @Output() deleteProducts = new EventEmitter<void>();
  /** refresh product */
  @Output() refreshProducts = new EventEmitter<void>();
  /** trigger to know when categories overlay panel is open */
  isCategoriesOpen = false;
  /** trigger to know when functions overlay panel is open */
  isFunctionsOpen = false;

  @ViewChild('batchCategories') batchCategories: ToolbarBatchCategoriesComponent;
  @ViewChild('batchFunctions') batchFunctions: ToolbarBatchFunctionsComponent;

  constructor(
    private http: HttpClient,
    private api: DefaultService,
    private alertService: AlertService
  ) {}

  ngOnInit(): void {}

  deleteProduct(): void {
    this.deleteProducts.emit();
  }

  editProduct(): void {
    this.editProducts.emit();
  }

  refreshProduct(): void {
    this.refreshProducts.emit();
  }

  searchChanged(data: DocumentSummary[]): void {
    this.searchChange.emit(data);
  }

  openCategories(event: Event): void {
    this.isCategoriesOpen = true;
    this.batchCategories.openCategory(event);
  }

  openFunctions(event: Event): void {
    this.isFunctionsOpen = true;
    this.batchFunctions.openFunction(event);
  }

  closeCategories(): void {
    this.isCategoriesOpen = false;
  }

  closeFunctions(): void {
    this.isFunctionsOpen = false;
  }
}
