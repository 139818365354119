import { Theme } from '@harmanpa/ng-cae';

export const defaultTheme: Theme = {
  name: 'Infinitive',
  seed: '#693292',
  properties: {
    dark: {
        "primary": "#cfbcff",
        "onPrimary": "#381e72",
        "primaryContainer": "#4f378a",
        "onPrimaryContainer": "#e9ddff",
        "secondary": "#cbc2db",
        "onSecondary": "#332d41",
        "secondaryContainer": "#4a4458",
        "onSecondaryContainer": "#e8def8",
        "tertiary": "#efb8c8",
        "onTertiary": "#4a2532",
        "tertiaryContainer": "#633b48",
        "onTertiaryContainer": "#ffd9e3",
        "error": "#ffb4ab",
        "onError": "#690005",
        "errorContainer": "#93000a",
        "onErrorContainer": "#ffb4ab",
        "background": "#1c1b1e",
        "onBackground": "#e6e1e6",
        "surface": "#1c1b1e",
        "onSurface": "#e6e1e6",
        "surfaceVariant": "#49454e",
        "onSurfaceVariant": "#cac4cf",
        "outline": "#948f99",
        "outlineVariant": "#49454e",
        "shadow": "#000000",
        "scrim": "#000000",
        "inverseSurface": "#e6e1e6",
        "inverseOnSurface": "#313033",
        "inversePrimary": "#6750a4",
        "primaryRGB": "207,188,255",
        "onPrimaryRGB": "56,30,114",
        "primaryContainerRGB": "79,55,138",
        "onPrimaryContainerRGB": "233,221,255",
        "secondaryRGB": "203,194,219",
        "onSecondaryRGB": "51,45,65",
        "secondaryContainerRGB": "74,68,88",
        "onSecondaryContainerRGB": "232,222,248",
        "tertiaryRGB": "239,184,200",
        "onTertiaryRGB": "74,37,50",
        "tertiaryContainerRGB": "99,59,72",
        "onTertiaryContainerRGB": "255,217,227",
        "errorRGB": "255,180,171",
        "onErrorRGB": "105,0,5",
        "errorContainerRGB": "147,0,10",
        "onErrorContainerRGB": "255,180,171",
        "backgroundRGB": "28,27,30",
        "onBackgroundRGB": "230,225,230",
        "surfaceRGB": "28,27,30",
        "onSurfaceRGB": "230,225,230",
        "surfaceVariantRGB": "73,69,78",
        "onSurfaceVariantRGB": "202,196,207",
        "outlineRGB": "148,143,153",
        "outlineVariantRGB": "73,69,78",
        "shadowRGB": "0,0,0",
        "scrimRGB": "0,0,0",
        "inverseSurfaceRGB": "230,225,230",
        "inverseOnSurfaceRGB": "49,48,51",
        "inversePrimaryRGB": "103,80,164",
        "surfaceContainerLowest": "#0f0e11",
        "surfaceContainerLow": "#1c1b1e",
        "surfaceContainer": "#201f22",
        "surfaceContainerHigh": "#2b292d",
        "surfaceContainerHighest": "#363438"
    },
    light: {
        "primary": "#6750a4",
        "onPrimary": "#ffffff",
        "primaryContainer": "#e9ddff",
        "onPrimaryContainer": "#22005d",
        "secondary": "#625b71",
        "onSecondary": "#ffffff",
        "secondaryContainer": "#e8def8",
        "onSecondaryContainer": "#1e192b",
        "tertiary": "#7e5260",
        "onTertiary": "#ffffff",
        "tertiaryContainer": "#ffd9e3",
        "onTertiaryContainer": "#31101d",
        "error": "#ba1a1a",
        "onError": "#ffffff",
        "errorContainer": "#ffdad6",
        "onErrorContainer": "#410002",
        "background": "#fffbff",
        "onBackground": "#1c1b1e",
        "surface": "#fffbff",
        "onSurface": "#1c1b1e",
        "surfaceVariant": "#e7e0eb",
        "onSurfaceVariant": "#49454e",
        "outline": "#7a757f",
        "outlineVariant": "#cac4cf",
        "shadow": "#000000",
        "scrim": "#000000",
        "inverseSurface": "#313033",
        "inverseOnSurface": "#f4eff4",
        "inversePrimary": "#cfbcff",
        "primaryRGB": "103,80,164",
        "onPrimaryRGB": "255,255,255",
        "primaryContainerRGB": "233,221,255",
        "onPrimaryContainerRGB": "34,0,93",
        "secondaryRGB": "98,91,113",
        "onSecondaryRGB": "255,255,255",
        "secondaryContainerRGB": "232,222,248",
        "onSecondaryContainerRGB": "30,25,43",
        "tertiaryRGB": "126,82,96",
        "onTertiaryRGB": "255,255,255",
        "tertiaryContainerRGB": "255,217,227",
        "onTertiaryContainerRGB": "49,16,29",
        "errorRGB": "186,26,26",
        "onErrorRGB": "255,255,255",
        "errorContainerRGB": "255,218,214",
        "onErrorContainerRGB": "65,0,2",
        "backgroundRGB": "255,251,255",
        "onBackgroundRGB": "28,27,30",
        "surfaceRGB": "255,251,255",
        "onSurfaceRGB": "28,27,30",
        "surfaceVariantRGB": "231,224,235",
        "onSurfaceVariantRGB": "73,69,78",
        "outlineRGB": "122,117,127",
        "outlineVariantRGB": "202,196,207",
        "shadowRGB": "0,0,0",
        "scrimRGB": "0,0,0",
        "inverseSurfaceRGB": "49,48,51",
        "inverseOnSurfaceRGB": "244,239,244",
        "inversePrimaryRGB": "207,188,255",
        "surfaceContainerLowest": "#ffffff",
        "surfaceContainerLow": "#f7f2f7",
        "surfaceContainer": "#f1ecf1",
        "surfaceContainerHigh": "#ece7eb",
        "surfaceContainerHighest": "#e6e1e5"
    }
  }
}



