import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MenuGroup } from '../../shared/model/menu-group.model';
import { MenuItemComponent } from '../../shared/components/menu-item/menu-item.component';
import { Router, RouterLink } from '@angular/router';
import { WorkspaceMenuComponent } from './workspace-menu/workspace-menu.component';
import { MenuItem } from 'primeng/api';
import { NgOptimizedImage } from '@angular/common';


@Component({
  selector: 'inf-sidenav',
  standalone: true,
  imports: [
    CommonModule,
    MenuItemComponent,
    RouterLink,
    WorkspaceMenuComponent,
    NgOptimizedImage
  ],
  templateUrl: './sidenav.component.html',
})
export class SidenavComponent {
  @Input() menu: MenuGroup[] = [];

  constructor(private router: Router) {
  }

  groupTrackBy(index: number, group: MenuGroup):string{
    return group.label as string;
  }

  itemTrackBy(index: number, item: MenuItem):string{
    return item.label as string;
  }

}
