<div class="notification pr-5">
  <div class="notification-content flex">
    <div class="notification-ellipse relative">
      <div *ngIf="!task.isRead"></div>
    </div>

    <div class="flex pb-1 w-full">
      <div class="w-full">
        <div class="flex mb-1" style="width:330px">
          <!-- <span class="body-small-semibold">{{task.type | javaClass}}: </span> -->
          <span [title]="task.moduleName" class="max-w-10rem body-small-semibold white-space-nowrap overflow-hidden text-overflow-clip">
            <span class="body-small-semibold white-space-nowrap text-overflow-clip">{{task.moduleName}}</span></span>
          <span [title]="task.title" class="w-8 body-small text-overflow-ellipsis white-space-nowrap overflow-hidden">: {{ task.title }}</span>
        </div>

        <div class="flex mb-1">
          <span class="body-xtra-small-semibold">{{ task.children.length }} {{ task.children.length > 1 ? "tasks" : "task"}}{{task.status !== 'Complete' ? ":": " - "}} </span>
          <div class="{{task.status}} bg-transparent flex">
            <span class="body-xtra-small-semibold">{{((task.progress||0.0) * 100) | number : '1.0-0' }}% progress</span>
          </div>
        </div>

        <p-button
          styleClass="p-button-text p-button-sm p-0 details-btn bg-transparent"
          [label]="isDetailsopened ? 'close': 'details'"
          (onClick)="toggleDetails()"
        ></p-button>
      </div>

      <div class="flex flex-column justify-content-center">
        <p-chip [styleClass]="task.status + ' squared-chip'" class="block ml-auto">
          <div class="notification-chip">
            <span class="body-xtra-small-semibold">{{ task.status }}</span>
          </div>
        </p-chip>
        <span class="block text-right body-xtra-small time white-space-nowrap">{{ started | async | since : false : 'toNowStrict' : true }}</span>
      </div>
    </div>
  </div>

  <ng-container *ngIf="isDetailsopened">
    <div *ngFor="let child of task.children; let first = first;" class="flex notification-details mb-1">
      <div
        class="pt-2 ml-4 w-full">
        <div class="flex mb-2" style="width:380px">
          <div class="notification-ellipse-grey border-circle mr-2 mt-1"></div>
          <span class="body-xtra-small-semibold white-space-nowrap">{{child.title}}: </span>
          <!-- <span class="body-xtra-small ml-1" [alt]="child.description">{{ child.description | truncate: [38, '...'] }}</span> -->
          <span class="body-xtra-small ml-1 white-space-nowrap text-overflow-ellipsis overflow-hidden" [alt]="child.description">{{ child.description}}</span>
        </div>
        <div class="flex align-items-center ml-3">
          <!-- <span class="block text-right body-xtra-small time white-space-nowrap">{{ started | async | since : false : 'toNowStrict' : true }}</span> -->
          <p-chip [styleClass]="child.status + ' squared-chip'" class="block">
            <div class="notification-chip p-0">
              <span class="body-xtra-small-semibold">{{child.status}}<ng-container *ngIf="child.status==='Running'"> {{child.progress * 100 | number : '1.0-0' }}%</ng-container>
              </span>
            </div>
          </p-chip>
        </div>
      </div>
      <div *ngIf=" child.status !== 'Complete'" class="py-2 flex flex-column justify-content-end align-items-end details-button">
        <div *ngIf="child.taskType === 'task'" class="flex">
<!--          <div *ngIf="child.taskType === 'task' && child.status === 'Failed'" class="flex">-->
          <button *ngIf="child.status === 'Running' || child.status === 'Submitted' || child.status === 'Scheduled'" pButton 
                  icon="pi pi-times"
                  class="p-button-rounded p-button-text p-button-sm ml-2"
                  pTooltip="Cancel task"
                  tooltipPosition="bottom"
                  (click)="cancel(child)">
          </button>
          <button *ngIf="age > 600000"
                  pButton icon="pi pi-refresh"
                  class="p-button-rounded p-button-text p-button-sm ml-2"
                  pTooltip="Restart task"
                  tooltipPosition="bottom"
                  (click)="retry(child)"></button>
          <button *ngIf="child.status === 'Failed'"
                  pButton icon="pi pi-exclamation-circle"
                  class="p-button-rounded p-button-text p-button-sm ml-2"
                  pTooltip="Report task error to support"
                  tooltipPosition="bottom"
                  (click)="report(child)"></button>
        </div>
      </div>
    </div>
  </ng-container>
</div>
<p-divider *ngIf="!last"></p-divider>
