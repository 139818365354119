import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonModule } from 'primeng/button';
import { RippleModule } from 'primeng/ripple';
import { StyleClassModule } from 'primeng/styleclass';

@Component({
  templateUrl: './solutions.component.html',
  selector: 'inf-landing-solutions',
  standalone: true,
  imports: [CommonModule, ButtonModule, RippleModule, StyleClassModule],
})
export class LandingSolutionsComponent {}
