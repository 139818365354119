<div class="p-2 integration-card flex flex-row gap-2">
  <a [routerLink]="['/integrations']" [queryParams]="{configure: integration.name}"
    *ngFor="let integration of integrations; index as i; trackBy: integrationTrackBy" class="align-content-center justify-content-center border-round-md integration-image p-3"
    [pTooltip]="integration.description || ''">
    <ng-container *ngIf="images[i]">
      <img [alt]="integration.name" [ngSrc]="images[i]" width="38" height="38" class="img-object-contain" priority />
    </ng-container>
  </a>
  <a class="border-round-md integration-image p-4" [routerLink]="['/integrations']"
    pTooltip="Add an integration from the marketplace">
    <i class="pi pi-plus"></i>
  </a>
</div>