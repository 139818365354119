<div [ngClass]="dashboard ? 'flex ':'flex flex-column'" class="gap-4 orders-status">
  <inf-status-box
    class="flex-1"
    [class]="'received'"
    [count]="0"
    icon="pi pi-inbox"
    info="0 Received"
    [status]="'incoming'"></inf-status-box>
  <inf-status-box
    class="flex-1"
    [class]="'ongoing'"
    [count]="0"
    icon="pi pi-reply"
    info="0 Processing"
    [status]="'ongoing'"></inf-status-box>
  <inf-status-box
    class="flex-1"
    [class]="'delivered'"
    [count]="0"
    icon="pi pi-check-circle"
    info="0 Delivered"
    [status]="'completed'"></inf-status-box>
</div>
