<div class="fill-v">
  <div class="align-items-center flex justify-content-between">
    <h2 class="font-medium mb-2">Customers</h2>
  </div>
  <div class="font-medium subtitle mb-6">CONFIGURATOR CUSTOMERS</div>
  <div class="flex justify-content-between align-items-center mb-3">
    <div *ngIf="loading">Loading customers...</div>
<!--    <div-->
<!--      style="-->
<!--        display: flex;-->
<!--        justify-content: space-around;-->
<!--        align-items: center;-->
<!--        gap: 20px;-->
<!--      ">-->
<!--      <div>-->
<!--        <h3>Browser Usage</h3>-->
<!--        <p-chart-->
<!--          type="pie"-->
<!--          [data]="browserUsageData"-->
<!--          style="max-width: 400px; max-height: 400px"></p-chart>-->
<!--      </div>-->

<!--      <div>-->
<!--        <h3>Customer Locations</h3>-->
<!--        <p-chart-->
<!--          type="bar"-->
<!--          [data]="countryChartData"-->
<!--          style="max-width: 400px; max-height: 400px"></p-chart>-->
<!--      </div>-->
<!--      <div *ngIf="productUsageStatistics">-->
<!--        <h2>Product Usage Statistics</h2>-->
<!--        <ul>-->
<!--          <li *ngFor="let key of (productUsageStatistics | keyvalue)">-->
<!--            {{ key.key }}: {{ key.value }}-->
<!--          </li>-->
<!--        </ul>-->
<!--      </div>-->
<!--      -->
<!--      <div *ngIf="configurationCount">-->
<!--        <h2>Configuration Count</h2>-->
<!--        <p>Total: {{ configurationCount.total }}</p>-->
<!--        <h3>By Date:</h3>-->
<!--        <ul>-->
<!--          <li *ngFor="let dateRange of configurationCount.byDate">-->
<!--            From {{ dateRange.since | date }} to {{ dateRange.before | date }}: {{ dateRange.count }}-->
<!--          </li>-->
<!--        </ul>-->
<!--      </div>-->
<!--      -->
<!--    </div>-->
  </div>

  <div class="card flex flex-column">
    <!-- <inf-search-system
      class="mb-4"
      [filters]="filters"></inf-search-system> -->

    <p-table
      *ngIf="!loading"
      class="mb-5 flex-1"
      dataKey="id"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
      [globalFilterFields]="[
        'data.firstName',
        'data.lastName',
        'id',
        'data.customerID',
        'data.email',
        'data.orderStatus',
        'data.deliveryStatus'
      ]"
      [paginator]="customers.length != 0"
      [rowsPerPageOptions]="[10, 25, 50]"
      [rows]="rowsNumber"
      [scrollable]="false"
      [showCurrentPageReport]="true"
      [value]="customers">
      <ng-template
        pTemplate="caption"
        *ngIf="includeSearch">
        <inf-search-system class="mb-4"></inf-search-system>
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th
            style="width: 4rem"
            *ngIf="selectCustomers">
            <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
          </th>
          <th>Customer</th>
          <th>Since</th>
          <th>Configurations</th>
          <th>Orders</th>
          <th>Support Requests</th>
          <!-- <th>Delivery Status</th> -->
          <th></th>
        </tr>
      </ng-template>
      <ng-template
        let-value
        pTemplate="body">
        <tr>
          <td *ngIf="selectCustomers">
            <p-tableCheckbox [value]="value"></p-tableCheckbox>
          </td>
          <td>{{ value.user.email }}</td>
          <td>{{ value.user.signup_date | date : 'dd MMMM yyyy' }}</td>
          <td>{{ value.configurations.length }}</td>
          <td>{{ value.orders.length }}</td>
          <td></td>

          <!-- <td>{{ value.data.deliveryStatus }}</td> -->
          <td>
            <div class="flex gap-2">
              <button
                class="flex-shrink-0 p-button-text p-button-rounded"
                icon="pi pi-pencil"
                pButton
                [routerLink]="['/customers', value.user.email]"></button>
              <button
                class="flex-shrink-0 p-button-text p-button-rounded"
                icon="pi pi-ellipsis-v"
                pButton></button>
            </div>
          </td>
        </tr>
      </ng-template>
    </p-table>

    <inf-empty-state
      *ngIf="!customers.length"
      src="../../assets/images/empty-states/orders.svg"
      subtitle="No Active Customers"
      title="Active customers linked to your account will be listed here"></inf-empty-state>
  </div>
</div>
