import { Injectable } from '@angular/core';
import { BaseMeshFactory, MaterialStyling, MaterialType, Assembly, Part, GeometryService } from '@harmanpa/ng-cae';
import { Observable, isObservable, map } from 'rxjs';
import { BufferGeometry, Material, Mesh, Object3D } from 'three';
@Injectable()
export class ViewerMeshFactory extends BaseMeshFactory<Assembly, Part> {

  constructor(private geometryService: GeometryService) {
    super();
  }

  // export class ViewerMeshFactory extends BaseMeshFactory<ConfiguredAssembly, ConfiguredPart> {
  createMesh(
    rootAssembly: Assembly,
    path: string[],
    part: Part,
    geometry: BufferGeometry | Observable<BufferGeometry>,
    material: Material | Material[] | Observable<Material | Material[]>,
    type?: MaterialType,
    materialStyling?: MaterialStyling
  ): Observable<Object3D | Object3D[]> {
    console.log('creating mesh');
    const o = super.createMesh(rootAssembly,
      path,
      part,
      isObservable(geometry)
        ? geometry.pipe(map(g => this.prepareGeometry(g)))
        : this.prepareGeometry(geometry),
      material,
      type,
      materialStyling);
    console.log('created mesh', o);
    return o;
  }

  prepareGeometry(g: BufferGeometry): BufferGeometry {
    // TODO: Uncomment this and all geometry will be possible to be unwrapped
    // if (g.getAttribute('uv')) {
    //   console.log(g);
    //   g = this.geometryService.unwrappable(g);
    //   console.log(g);
    // }
    g.computeVertexNormals();
    return g;
  }
}



