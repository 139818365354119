import { Component, ElementRef, EventEmitter, Input, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { AlertService, DocumentSummary } from '@harmanpa/ng-cae';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { OverlayPanel, OverlayPanelModule } from 'primeng/overlaypanel';
import { HttpClient } from '@angular/common/http';
import { DefaultService } from 'generated-src';
import { MenuItem } from 'primeng/api';
import { Menu, MenuModule } from 'primeng/menu';
import { forkJoin, map, of, switchMap, timer } from 'rxjs';
import { TagComponent } from '../../tag/tag.component';

@Component({
  selector: 'inf-toolbar-batch-functions',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ButtonModule,
    InputTextModule,
    OverlayPanelModule,
    TagComponent,
    MenuModule
  ],
  templateUrl: './toolbar-batch-functions.component.html',
})
export class ToolbarBatchFunctionsComponent implements OnInit {
  /** All products */
  @Input() products: DocumentSummary[] = [];
  /** Selected products */
  @Input() selectedProducts: DocumentSummary[] = [];
  /** Emitter to know when overlay panel is closed */
  @Output() functionsClose = new EventEmitter<void>();

  @ViewChild("functionOp") functionOp: OverlayPanel;
  @ViewChild("extraOptionsMenu") extraOptionsMenu: Menu;
  @ViewChildren("searchFunctionInput") searchFunctionInput: QueryList<ElementRef>;
  
  
  //Functions variables
  /** Contains function input value */
  functionInput: string = "";
  /** Contains all functions */
  functions: string[] = [];
  /** Contains functions divided in suggestions and the ones in common with the selected products */
  functionsFiltered: {suggestions: string[], common: string[]} = {suggestions: [], common: []};
  /** Current functions according to the input */
  functionsSuggestions: string[] = [];
  /** function on which the extra option button was clicked */
  extraOptionsFunction = "";
  /** If it is renaming the function */
  isRenamingFunction = false;
  /** Contains the function renaming string */
  renamingFunctionStr = "";
  /** Contains the extra menu options */
  extraMenuOptions: MenuItem[] = [];

  constructor(private http: HttpClient, private api: DefaultService, private alertService: AlertService) {}

  ngOnInit(): void {
    this.extraMenuOptions = [
      {
        label: 'Rename',
        icon: 'pi pi-pencil',
        iconClass: 'mr-0 body-xtra-small',
        command: event => this.extraOptionClick("edit"),
      },
      {
        label: 'Delete',
        icon: 'pi pi-trash',
        iconClass: 'mr-0 body-xtra-small',
        styleClass: 'option-danger',
        command: event => this.extraOptionClick("delete"),
      }
    ];

    this.getFunctions();
  }

  /**
   * On key change on input, filter functions
   */
  searchFunction(event: any): void {
    if (event.key === 'Enter') {
      this.addFunction(this.functionInput);
    } else if (event.key === ',') {
      this.addFunction(this.functionInput.slice(0, -1));
    } else {
      //Get recommended functions
      this.functionsSuggestions = this.functionsFiltered.suggestions.filter(
        _function => _function !== this.functionInput && _function.toLowerCase().includes(this.functionInput.toLowerCase())
      );
    }
  }

  /**
   * Gets functions
   * @param updateFunctions - if it is to reset functions values after we get the new functions
   */
  getFunctions(updateFunctions = false): void {
    this.http.get<string[]>('/api/functions').subscribe({
      next: data => {
        this.functions = data;
        if (updateFunctions) {
          this.resetFunctionsSuggestions();
        }
      },
      error: e => {
        console.log('Error fetching functions:', e);
      },
    });
  }

  /**
   * Adds function
   * @param _function the function name
   */
  addFunction(_function: string): void {
    this.selectedProducts.forEach((product, i) => {
      //Get module (to have functions)
      this.api.getModule(product.id as string, product.head as string).subscribe((vModule) => {        
        if (vModule?.document) {
          //Add function
          vModule.document.functions ??= [];
          if (!vModule.document.functions.includes(_function)) {
            vModule.document.functions.push(_function);
          }
          
          //update module (with new functions)
          this.api.updateModule(vModule.id as string, vModule.version as string, vModule.document).subscribe((module) => {
            product.head = module.version;  //Update version
            //Update category
            product.data ??= [];
            product.data["functions"] = vModule.document?.functions;
            
            if (i === this.selectedProducts.length - 1) { //If Last function, send message and remove selections
              this.alertService.success(`Added function to selected product${this.selectedProducts.length > 1 ? "s" : ""} successfully`);
              this.getFunctions(true);
            }
          });
        }
      });
    });
  }

  /**
   * Opens the function panel
   */
  openFunction(event: Event): void {
    this.resetFunctionsSuggestions();
    this.functionOp.toggle(event);
  }

  /**
   * Deletes a function
   * @param function the function name
   * @param products the products where it is to delete the function
   */
  deleteFunction(_function: string, products: DocumentSummary[]): void {
    //Use a map of observables to be able to send a success message when all of them finishes
    const updateModules = products.map((product) => 
      this.api.getModule(product.id as string, product.head as string).pipe(
        switchMap((vModule) => {
          if (vModule?.document && (vModule.document.functions ?? []).includes(_function)) {
            vModule.document.functions = vModule.document.functions?.filter(func => func !== _function);
  
            // Return an observable of the updateModule
            return this.api.updateModule(vModule.id as string, vModule.version as string, vModule.document).pipe(
              map((module) => {
                //Update version and function data
                product.head = module.version;
                product.data ??= [];
                product.data["functions"] = vModule.document?.functions;
                return module;
              })
            );
          } else {
            return of(null);
          }
        })
      )
    );
  
    // Wait for all update operations to complete
    forkJoin(updateModules).subscribe({
      next: () => {
        this.alertService.success(`Function successfully deleted`);
        this.getFunctions(true);
      }
    });
  }

  /**
   * On key change on input, to rename function
   */
  renameFunction(event: KeyboardEvent): void {
    if (event.key === 'Enter') {  //Rename function
      //Use a map of observables to be able to send a success message when all of them finishes
      const renameModules = this.products.map((product) => 
        this.api.getModule(product.id as string, product.head as string).pipe(
          switchMap((vModule) => {
            if (vModule?.document && (vModule.document.functions ?? []).includes(this.extraOptionsFunction)) {              
              //Remove function
              vModule.document.functions = vModule.document.functions?.filter(_function => _function !== this.extraOptionsFunction);

              //Add function
              if (!vModule.document.functions?.includes(this.renamingFunctionStr)) {
                vModule.document.functions?.push(this.renamingFunctionStr);
              }

              // Return an observable of the updateModule
              return this.api.updateModule(vModule.id as string, vModule.version as string, vModule.document).pipe(
                map((module) => {
                  //Update version and function data
                  product.head = module.version;  
                  product.data ??= [];
                  product.data["functions"] = vModule.document?.functions;
                  return module;
                })
                );
            } else {
              return of(null);
            }
          })
        )
      );

      // Wait for all update operations to complete
      forkJoin(renameModules).subscribe({
        next: () => {
          this.alertService.success(`Function successfully renamed`);
          this.getFunctions(true);
          this.isRenamingFunction = false;
          this.renamingFunctionStr = "";
        }
      });
    } else if (event.key === 'Escape') {  //Cancel renaming
      this.isRenamingFunction = false;
      this.renamingFunctionStr = "";
    }
  }

  /**
   * On extra menu option click
   */
  extraOptionClick(option: "edit" | "delete"): void {
    switch (option) {
      case "delete":
        this.deleteFunction(this.extraOptionsFunction, this.products);
        break;
      default:
        this.isRenamingFunction = true;
        this.renamingFunctionStr = this.extraOptionsFunction;
        timer(0).subscribe(() => {  //Input is going to be true on next render, so focus on it
          this.searchFunctionInput.first.nativeElement.focus();
        });
        break;
    }
  }

  /**
   * Shows extra options menu
   * @param event - the button event
   * @param _function - the _function that got clicked
   */
  showExtraOptions(event: Event, _function: string): void {
    this.extraOptionsFunction = _function;
    this.extraOptionsMenu.toggle(event); 
    event.stopPropagation();
  }

  /**
   * Resets input and suggestions of functions
   */
  resetFunctionsSuggestions(): void {
    this.functionInput = "";

    //Get only functions that selectedProducts have in common
    this.functionsFiltered.common = this.selectedProducts.map(
      product => product.data?.["functions"] ?? []
    ).reduce((acc, functions) => acc.filter((_function: string) => functions.includes(_function)));

    //Get functions that arent in common
    this.functionsFiltered.suggestions = this.functions.filter(
      _function => !this.functionsFiltered.common.includes(_function)
    );

    this.functionsSuggestions = this.functionsFiltered.suggestions;
  }
  
  /**
   * On overlay hide
   */
  onOverlayHide(): void {
    this.isRenamingFunction = false;
    this.renamingFunctionStr = "";
    this.functionsClose.emit();
  }
}
