import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ColorPickerModule } from 'primeng/colorpicker';

@Component({
  selector: 'inf-theme-preset',
  standalone: true,
  templateUrl: './theme-preset.component.html',
  imports: [
    CommonModule,
    FormsModule,
    ColorPickerModule
  ],
})
export class ThemePresetComponent {
    @Input() themeNumber: number = 1;
    @Input() themeName: string = '';
    @Input() isSelected: boolean = false;
    @Input() primaryColor = "#000000";
    @Input() secondaryColor = "#000000";
    @Input() tertiaryColor = "#000000";

    constructor() {}
}
