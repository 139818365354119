/**
 * InfinitivePlatform API
 * Modular Configurator
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Point3D } from './point3D';
import { Vector3D } from './vector3D';

/**
 * Possible holes or other geometric features required
 */
export interface FeatureTemplate { 
    /**
     * Name of module
     */
    name?: string;
    /**
     * Description
     */
    description?: string;
    location?: Point3D;
    projectionAxis?: Vector3D;
    perpendicularAxis?: Vector3D;
    /**
     * Where is the feature (hole) located relative to the Projection Location?
     */
    featureLocation?: FeatureTemplate.FeatureLocationEnum;
    /**
     * The target might not be aligned with the projection axis, which does the feature align with?
     */
    featureAlignment?: FeatureTemplate.FeatureAlignmentEnum;
}
export namespace FeatureTemplate {
    export type FeatureLocationEnum = 'LineOfSight' | 'NonLineOfSight';
    export const FeatureLocationEnum = {
        LineOfSight: 'LineOfSight' as FeatureLocationEnum,
        NonLineOfSight: 'NonLineOfSight' as FeatureLocationEnum
    };
    export type FeatureAlignmentEnum = 'OnAxis' | 'OnTarget';
    export const FeatureAlignmentEnum = {
        OnAxis: 'OnAxis' as FeatureAlignmentEnum,
        OnTarget: 'OnTarget' as FeatureAlignmentEnum
    };
}
