/**
 * InfinitivePlatform API
 * Modular Configurator
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { CategoryState } from './categoryState';
import { ConfigurationView } from './configurationView';
import { ModuleAvailableParameters } from './moduleAvailableParameters';

export interface ConfigurationState { 
    categories?: { [key: string]: CategoryState; };
    views?: { [key: string]: ConfigurationView; };
    availableParameters?: { [key: string]: ModuleAvailableParameters; };
    status?: ConfigurationState.StatusEnum;
}
export namespace ConfigurationState {
    export type StatusEnum = 'Disconnected' | 'Incomplete' | 'Complete' | 'Invalid';
    export const StatusEnum = {
        Disconnected: 'Disconnected' as StatusEnum,
        Incomplete: 'Incomplete' as StatusEnum,
        Complete: 'Complete' as StatusEnum,
        Invalid: 'Invalid' as StatusEnum
    };
}
