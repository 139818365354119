import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthService, User, UserService } from '@harmanpa/ng-cae';
import { ProfileService } from '../../services/profile.service';
import { NgOptimizedImage } from '@angular/common';

@Component({
  selector: 'inf-avatar',
  standalone: true,
  imports: [CommonModule,NgOptimizedImage],
  templateUrl: './avatar.component.html',
})
export class AvatarComponent {
  @Input() initials: string = '';
  @Input() size: 'small' | 'medium' | 'large' = 'medium';
  @Input() label: string = '';
  @Input() user: User = { email: '', workspace: '' };
  alt: undefined | string = '';
  src: string = '';

  constructor(
    private authService: AuthService,
    private userService: UserService,
    private profileService: ProfileService
  ) {}

  ngOnInit(): void {
    if (this.userService.isGuest(this.user as User)) {
      this.src = 'assets/images/misc/avatars/guest.svg';
    } else {
      this.src =
        '/api/users/' +
        (typeof this.user === 'string'
          ? (this.user as string)
          : (this.user as User).email) +
        '.png?token=' +
        this.authService.getToken();
    }
    this.alt =
      typeof this.user === 'string'
        ? (this.user as string)
        : (this.user as User).name
        ? (this.user as User).name
        : (this.user as User).email;

    // Listen to see if the image was updated
    if (!this.userService.isGuest(this.user as User)) {
      this.profileService.changedProfileImg.subscribe((imgPath: string) => {
        this.src = imgPath; //Set new path
      });
    }
  }
}
