import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonModule } from 'primeng/button';
import { NgOptimizedImage } from '@angular/common';

@Component({
  selector: 'inf-integration-box',
  standalone: true,
  imports: [
    ButtonModule, 
    CommonModule,
    NgOptimizedImage
  ],
  templateUrl: './integration-box.component.html',
})
export class IntegrationBoxComponent {
  @Input() isConnected: boolean = false;
  @Input() name: string = '';
  @Input() src: string = '';
}
