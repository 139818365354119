<div class="pricing-plans">
    <!-- <div class="px-4 py-2 md:px-4 lg:px-2"> -->
    <!-- <div class="text-900 font-bold text-4xl mb-4 text-center">Pricing Plans</div> -->
    <div class="text-700 mb-4 line-height-3">Explore our flexible pricing plans designed to grow your build-to-order
        pipeline.</div>

    <div class="surface-ground grid grid-nogutter">
        <div *ngFor="let plan of plans; trackBy: planTrackBy" class="col-12 sm:col-12 md:col-12 lg:col-3">
            <div class="h-full p-3">
                <div class="shadow-2 p-4 h-full flex flex-column surface-card"
                    [ngClass]="{'active': selectedPlan == plan.name}" style="border-radius: 6px">
                    <div class="font-medium font-bold text-xl mb-2">{{plan.name}}</div>
                    <div class="body-small">{{plan.description}}</div>
                    <hr class="my-3 mx-0 border-top-1 border-none surface-border" />
                    <div class="flex align-items-center">
                        <span *ngIf="plan.price<0" class="font-bold heading-medium">Contact us</span>
                        <span *ngIf="plan.price>=0" class="font-bold heading-medium">£{{plan.price}}</span>
                        <span *ngIf="plan.price>=0" class="ml-2 font-medium">per month</span>
                    </div>
                    <hr class="my-3 mx-0 border-top-1 border-none surface-border" />
                    <ul class="list-none p-0 m-0 flex-grow-1">
                        <li class="flex align-items-center mb-3" *ngFor="let feature of plan.features; trackBy: planTrackBy">
                            <i class="pi pi-check-circle text-green-500 mr-2"></i>
                            <span>{{feature}}</span>
                        </li>
                    </ul>
                    <hr class="mb-3 mx-0 border-top-1 surface-border mt-auto" />
                    <button *ngIf="selectedPlan === plan.name" pButton pRipple label="Upgrade"
                        class="w-full mt-auto p-button-outlined" (click)="portal()"></button>
                    <button *ngIf="plan.price<0" pButton pRipple label="Contact Us" class="w-full mt-auto"
                        (click)="findOutMore()"></button>
                    <button *ngIf="selectedPlan != plan.name && plan.price>=0 " pButton pRipple label="Switch"
                        class="w-full mt-auto" (click)="portal()"></button>
                </div>
            </div>
        </div>
    </div>