/**
 * InfinitivePlatform API
 * Modular Configurator
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface CategoryMessage { 
    status?: CategoryMessage.StatusEnum;
    title?: string;
    message?: string;
}
export namespace CategoryMessage {
    export type StatusEnum = 'Incomplete' | 'Complete' | 'Invalid' | 'Optional' | 'OK';
    export const StatusEnum = {
        Incomplete: 'Incomplete' as StatusEnum,
        Complete: 'Complete' as StatusEnum,
        Invalid: 'Invalid' as StatusEnum,
        Optional: 'Optional' as StatusEnum,
        OK: 'OK' as StatusEnum
    };
}
