<div class="relative overflow-hidden surface-section px-4 py-8 md:px-6 lg:px-8" id="hero">
  <img ngSrc="assets/images/misc/hero-2.jpg" alt="Image" class="absolute top-0 left-0 w-auto h-full block md:w-full" height="300" width="1240"/>

  <div class="md:text-center lg:text-center flex-column align-items-center my-6 relative top-6">
    <div class="display-small text-semibold text-white mb-2">Let Customers Self-Serve</div>
    <div class="display-small text-semibold text-primary-300 mb-4">Complex Customised or Modular Products</div>
    <div class="heading-medium sm:body-medium text-semibold text-white mb-4">
      with the first Configurator-to-Production cloud platform
    </div>
    <p class="body-small text-regular mt-0 mb-0 mx-auto text-white">
      Our cloud-based platform empowers all manufacturers of build-to-order products
    </p>
    <p class="body-small text-regular mt-1 mb-4 mx-auto text-white">
      Join the future of choice!
    </p>
    <button (click)="onRequestDemo()" class="mb-4 body-medium lg:w-6 md:w-6 w-12" pButton pRipple label="Ask for a demo"
      type="button"></button>
  </div>
</div>