<div class="header py-3 px-3 lg:px-6 md:px-6 shadow-2 flex align-items-center justify-content-between fixed"
  [style]="small? 'min-height:72px':'min-height:84px'">
  <img *ngIf="small" ngSrc="assets/images/logos/infinitive-large.png" alt="Infinitive Logo" height="30" width="48" (click)="onLogoClick()" />
  <img *ngIf="!small" ngSrc="assets/images/logos/infinitive-large.png" alt="Infinitive Logo" height="50" width="80" (click)="onLogoClick()" />
  <a pRipple class="cursor-pointer block lg:hidden text-white" pStyleClass="@next" enterClass="hidden"
    leaveToClass="hidden" (click)="toggleNav()">
    <i class="pi pi-bars text-2xl"></i>
  </a>

  <div [style]="isMenuHidden? 'display:none':'display:inline;background-color: #ffffff;'"
    class="align-items-center flex-grow-1 lg:justify-content-end lg:flex absolute lg:static w-full left-0 top-100 px-6 lg:px-0 z-3 shadow-2 lg:shadow-none"
    >
    <ul
      class="list-none p-0 m-0 flex lg:align-items-center text-white select-none flex-column lg:flex-row cursor-pointer">
      <li>
        <div (click)="onClickScroll('feature')" pRipple
          class="flex px-0 lg:px-5 py-3  body-small transition-colors transition-duration-150">
          <span>Features</span>
        </div>
      </li>
      <li>
        <a (click)="onClickScroll('team')" pRipple
          class="flex px-0 lg:px-5 py-3  body-small transition-colors transition-duration-150">
          <span>Team</span>
        </a>
      </li>
      <li>
        <div (click)="onClickScroll('contact')" pRipple
          class="flex px-0 lg:px-5 py-3 body-small transition-colors transition-duration-150">
          <span>Contact</span>
        </div>
      </li>
    </ul>
    <div
      class="flex justify-content-between lg:block border-top-1 lg:border-top-none surface-border py-3 lg:py-0 mt-3 lg:mt-0 lg:ml-5">
      <button (click)="onLoginButtonClick()" pButton pRipple
              [label]="authService.isAuthenticated() ? 'Dashboard' : 'Login'"
              [icon]="'pi pi-' + (authService.isAuthenticated() ? 'home' : 'user')"
        class="p-button font-bold"></button>
    </div>
  </div>
</div>
