<div
    class="inf-notification-button mr-4 flex justify-content-center align-items-center"
    #notificationsButton
>
  <button pButton pRipple type="button" icon="pi pi-bolt" class="body-medium p-2 p-button-rounded p-button-text" pTooltip="Connecting..."
      tooltipPosition="top" *ngIf="!connected"></button>
  <button pButton pRipple type="button" icon="pi pi-bell" class="body-medium p-2 p-button-rounded p-button-text" [pTooltip]="tooltip"
      tooltipPosition="top" *ngIf="connected && numberTotal==0" (click)="notificationToggle($event)"></button>
  <button pButton class="body-medium my-2 p-button-rounded p-button-text" [pTooltip]="tooltip" tooltipPosition="top"
      *ngIf="connected && numberTotal>0" (click)="notificationToggle($event)">
      <p-progressSpinner class="flex" [style]="{
              width: '40px',
              height: '40px',
          }" strokeWidth="3"
          [class.stop-spinner]="numberActive===0"
          animationDuration="2s"></p-progressSpinner>
      <div class="inf-notifications-count">
          <span class="body-medium">
              {{ numberTotal }}
          </span>
      </div>
  </button>
</div>
<p-overlayPanel styleClass="inf-notifications-panel" #notificationsPanel appendTo="body" (onHide)="hidePanel()">
  <ng-template pTemplate>
      <div class="inf-notifications-header-sticky">
          <div class="inf-notifications-header">
              <h4 class="body-large-semibold mb-1">Notifications</h4>
              <div class="flex justify-content-between">
                  <p class="body-xtra-small">Check the progress of your tasks.</p>
                  <p-button label="Clear completed" styleClass="p-button-link p-0 body-xtra-small" (click)="clearCompleted()"
                      [disabled]="numberComplete==0"></p-button>
              </div>
          </div>
      </div>
      <inf-notification-panel class="inf-notification-panel flex flex-column overflow-y-scroll" [tasks]="tasks"
          [integrations]="integrations"></inf-notification-panel>
  </ng-template>
</p-overlayPanel>

