<ng-container *ngIf="isModalOpen">
  <div
    class="modal-overlay"
    (click)="maskClick()"></div>

  <div class="border-round-lg flex flex-column modal p-4">
    <h6 class="font-bold">{{ header }}</h6>
    <p-divider class="my-2"></p-divider>
    <div class="modal-content">
      <ng-content></ng-content>
      <div class="flex gap-3 mt-4">
        <button *ngIf="cancelLabel != ''"
          class="p-button-outlined p-button-sm w-full"
          (click)="cancel()"
          [label]="cancelLabel"
          pButton></button>
        <button
          class="p-button-sm w-full"
          (click)="confirm()"
          [label]="confirmLabel"
          pButton></button>
      </div>
    </div>
  </div>
</ng-container>
