import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableModule } from 'primeng/table';
import { InputSwitchModule } from 'primeng/inputswitch';
import { FormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';

@Component({
  selector: 'inf-subscription-pricing',
  standalone: true,
  imports: [
    ButtonModule,
    CommonModule,
    FormsModule,
    InputSwitchModule,
    TableModule,
  ],
  templateUrl: './subscription-pricing.component.html',
})
export class SubscriptionPricingComponent {
  checked: boolean = false;
  tableValues = [
    { name: 'Arcu purus', basic: true, standard: true, advanced: true },
    { name: 'Posuere felis', basic: true, standard: true, advanced: true },
    { name: 'Curabitur mollis', basic: true, standard: true, advanced: true },
    { name: 'Vitae odio', basic: true, standard: true, advanced: true },
    { name: 'Orci, mi', basic: false, standard: false, advanced: false },
    {
      name: 'Tincidunt vulputate',
      basic: false,
      standard: false,
      advanced: false,
    },
    { name: 'Quis felis', basic: false, standard: false, advanced: false },
    {
      name: 'Dignissim vivamus',
      basic: 'Up to 5 files',
      standard: 'Up to 5 files',
      advanced: 'Up to 5 files',
    },
  ];

  isValueBoolean(value: boolean | string): boolean {
    return typeof value === 'boolean';
  }
}
