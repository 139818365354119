/**
 * InfinitivePlatform API
 * Modular Configurator
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { MaterialColor } from './materialColor';
import { MaterialTexture } from './materialTexture';
import { ReferenceAppearance } from './referenceAppearance';

/**
 * An appearance that can be applied to an assembly, part or face
 */
export interface Appearance { 
    base?: ReferenceAppearance;
    /**
     * Material type
     */
    type?: Appearance.TypeEnum;
    /**
     * Name of appearance
     */
    name?: string;
    /**
     * Category of appearance
     */
    category?: string;
    /**
     * Description
     */
    description?: string;
    /**
     * roughness
     */
    roughness?: number;
    /**
     * metalness
     */
    metalness?: number;
    emissive?: MaterialColor;
    map?: MaterialTexture;
    /**
     * transparent
     */
    transparent?: boolean;
    /**
     * opacity
     */
    opacity?: number;
    /**
     * transmission
     */
    transmission?: number;
    displacementMap?: MaterialTexture;
    /**
     * displacementScale
     */
    displacementScale?: number;
    color?: MaterialColor;
}
export namespace Appearance {
    export type TypeEnum = 'Basic' | 'Phong' | 'Standard' | 'Physical';
    export const TypeEnum = {
        Basic: 'Basic' as TypeEnum,
        Phong: 'Phong' as TypeEnum,
        Standard: 'Standard' as TypeEnum,
        Physical: 'Physical' as TypeEnum
    };
}
