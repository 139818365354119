import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonModule } from 'primeng/button';

@Component({
  selector: 'inf-tag',
  standalone: true,
  imports: [CommonModule, ButtonModule],
  templateUrl: './tag.component.html',
})
export class TagComponent {
  //If undefined, text style isnt changed
  @Input() isSmall: boolean | undefined = false;
  @Input() label: string = '';
  @Input() status: string = '';
  @Input() classes: string = '';
  @Input() style: { [_style: string]: any; } = {};
}
