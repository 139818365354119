import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, ElementRef, Renderer2 } from '@angular/core';
import { NgOptimizedImage } from '@angular/common';


@Component({
  templateUrl: './logo-clouds.component.html',
  selector: 'inf-landing-logo-clouds',
  standalone: true,
  imports: [CommonModule, NgOptimizedImage],
})
export class LandingLogoCloudsComponent implements AfterViewInit{
  constructor(private renderer: Renderer2, private el: ElementRef) {}

  ngAfterViewInit():void  {
    const imageConfig = [
      { id: 'rak', linkUrl: 'https://www.rakwireless.com' },
      { id: 'fjs', linkUrl: 'https://joinerysystems.com' },
      {
        id: 'evenKeel',
        linkUrl: 'https://www.evenkeelinsoles.com/',
      },
    ];

    imageConfig.forEach(config => {
      const imageElement = this.el.nativeElement.querySelector(`#${config.id}`);

      if (imageElement) {
        this.renderer.listen(imageElement, 'click', () => {
          window.open(config.linkUrl, '_blank');
        });
      }
    });
  }
   
  
}
