<div class="card flex flex-column">
  <div class="pb-4">
    <div class="flex align-items-center justify-content-between mb-3">
      <p class="label-normal font-bold">ORDERS</p>
      <p-button
        icon="pi pi-arrow-right"
        iconPos="right"
        label="See all"
        [routerLink]="['/orders']"
        class="primary"
        styleClass="p-button-text p-button-sm"></p-button>
    </div>
    <p-divider></p-divider>
  </div>
</div>
