<div class="workspace-menu flex flex-column py-4">
  <p-divider class="mb-2"></p-divider>
  <div class="align-items-center flex justify-content-between mb-2">
    <div class="font-medium">WORKSPACES</div>
    <button class="flex-shrink-0 p-button-text p-button-sm p-button-rounded" icon="pi pi-plus" pButton
      [routerLink]="['/admin-space', 'new-workspace']"></button>
  </div>
  <div class="flex justify-content-center" *ngIf="workspaces">
    <p-button
      *ngIf="showChevrons"
      [disabled]="!clickableLeftChevron"
      icon="pi pi-chevron-left"
      class="flex align-items-center"
      (click)="scroll(false)"
      styleClass="p-button-rounded p-button-text chevron-left-button p-button-sm"
    ></p-button>
    <div class="overflow-hidden" #workspacesContainer >
      <div content [class.gap-3]="this.workspaces.length < 4" class="flex gap-1 justify-content-center w-fit min-w-full">
        <inf-letters-avatar *ngIf="currentWorkspace && currentWorkspace.alias" class="workspace active"
          tooltipPosition="top" [text]="currentWorkspace.name" [color]="currentWorkspace.color" [pTooltip]="''+currentWorkspace.name" />
        <!-- [pTooltip]="'' + currentWorkspace.name + ': ' + currentWorkspace.description" -->
        <ng-container *ngFor="let workspace of workspaces; trackBy: workspaceTrackBy">
          <inf-letters-avatar *ngIf="workspace.alias != currentWorkspace.alias" class="workspace"
            (click)="changeWorkspace(workspace, false)" tooltipPosition="top" [text]="workspace.name" [color]="workspace.color"
            [pTooltip]="'' + workspace.name" />
          <!-- + ': ' + workspace.description" -->
        </ng-container>
        <div *ngFor="let emptyWorkspace of [].constructor(emptyWorkspaces);" class="workspace no-workspace opacity-100">
        </div>
      </div>
    </div>
    <p-button
      *ngIf="showChevrons"
      [disabled]="!clickableRightChevron"
      icon="pi pi-chevron-right"
      class="flex align-items-center"
      (click)="scroll(true)"
      styleClass="p-button-rounded p-button-text chevron-right-button p-button-sm"
    ></p-button>
  </div>
</div>
