<div class="align-items-center flex gap-3 search-system">
  <div class="p-input-icon-left w-6 search-bar">
    <i class="pi pi-search"></i>
    <input
      [(ngModel)]="query"
      (ngModelChange)="filterData()"
      pInputText
      placeholder="Search"
      type="text" />
    <p-button
      *ngIf="query"
      icon="fas fa-xmark"
      styleClass="p-button-text search-clear"
      (click)="clearQuery()"></p-button>
  </div>

  <!-- <p-dropdown
    *ngFor="let filter of filters"
    [(ngModel)]="filter.value"
    [options]="filter.options"
    [optionLabel]="filter.optionLabel"
    [placeholder]="filter.label"></p-dropdown> -->
</div>
