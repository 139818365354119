import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ProfileService {
    private _changedProfileImg: Subject<string> = new Subject();
    
    /**Observable containing the changed img path */
    public readonly changedProfileImg: Observable<string> = this._changedProfileImg.asObservable();

    constructor() {
    }

    /** Updates the img path subject */
    public set setChangedProfileImage(imgPath: string) {
        this._changedProfileImg.next(imgPath);
    }
}
