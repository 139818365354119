<div class="toolbar-batch-editor">
  <inf-search-system
    class="flex-1"
    [data]="products"
    [paths]="['data.name', 'data.categories']"
    (dataChange)="searchChanged($event)">
  </inf-search-system>

  <ng-container *ngIf="showBatchAction">
    <div class="batch-actions flex align-items-center mt-2">
      <ng-container *ngIf="selectedProducts.length">
        <p class="body-small">{{selectedProducts.length}} Product{{selectedProducts.length > 1 ? "s" : ""}} Selected</p>
        <p-button
          pTooltip="Functions"
          tooltipPosition="top"
          [class.active]="isFunctionsOpen"
          icon="pi pi-tag"
          styleClass="p-button-text p-button-rounded p-button-sm"
          class="mr-2"
          (click)="openFunctions($event)"
        ></p-button>
        <p-button
          pTooltip="Categories"
          tooltipPosition="top"
          [class.active]="isCategoriesOpen"
          icon="pi pi-cog"
          styleClass="p-button-text p-button-rounded p-button-sm"
          (click)="openCategories($event)"
        ></p-button>
        <div class="vertical-line surface-secondary-dark align-self-stretch my-1"></div>
        <p-button    
          pTooltip="Edit"
          tooltipPosition="top"
          icon="pi pi-external-link"
          styleClass="p-button-text p-button-rounded p-button-sm"
          class="mr-2"
          (click)="editProduct()"
        ></p-button>
        <p-button
          pTooltip="Delete"
          tooltipPosition="top"
          icon="pi pi-trash"
          styleClass="p-button-text p-button-rounded p-button-sm"
          (click)="deleteProduct()"
        ></p-button>
      </ng-container>
    </div>

    <inf-toolbar-batch-functions
      #batchFunctions
      [products]="products" 
      [selectedProducts]="selectedProducts"
      (functionsClose)="closeFunctions()">
    </inf-toolbar-batch-functions>

    <inf-toolbar-batch-categories 
      #batchCategories
      [products]="products" 
      [selectedProducts]="selectedProducts"
      (categoryClose)="closeCategories()">
    </inf-toolbar-batch-categories>
  </ng-container>
</div>
