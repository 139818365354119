<!-- NOT USED -->
<div class="border-round-lg connection flex gap-2 justify-content-between p-3">
  <div class="flex gap-3">
    <img
      alt="Onshape"
      class="connection-image p-2"
      [src]="[connection.image]" />
    <div class="flex flex-column">
      <div class="mb-1 font-medium">{{ connection.name }}</div>
      <div class="flex-1 mb-2 text-secondary text-sm">
        {{ connection.description }}
      </div>
      <inf-tag
        class="flex"
        [isSmall]="true"
        label="connected"
        status="success"></inf-tag>
    </div>
  </div>
  <button
    class="flex-shrink-0 p-button-rounded p-button-text"
    (click)="deleteChange.emit()"
    icon="pi pi-minus"
    pButton></button>
</div>
