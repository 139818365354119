<p-overlayPanel styleClass="popup-batch-editor radius-minimal mb-1" (onHide)="onOverlayHide()" #functionOp>  
  <!-- Functions in common -->
  <div class="common">
    <div *ngFor="let _function of functionsFiltered.common;" class="inline-flex relative">
      <inf-tag [isSmall]="true" [label]="_function" class="inline-flex" classes="px-1" status="info"></inf-tag>
      <i class="pi pi-times right-0 absolute top-50" (click)="deleteFunction(_function, selectedProducts)"></i>
    </div>
  </div>

  <input
    class="shadow-none border-noround border-none border-bottom-1 py-1 body-small"
    [(ngModel)]="functionInput"
    (keyup)="searchFunction($event)"
    pInputText
    placeholder="Search or Create new"
    type="text" /> 
    
  <p *ngIf="functionInput || functionsSuggestions.length == 0" class="helper-text body-xtra-small font-italic my-2">Press Enter or comma to create a new function</p>

  <!-- Functions suggestions -->
  <div class="options">
    <ng-container *ngFor="let _function of functionsSuggestions;" class="flex align-items-center">
      <p-button
        *ngIf="!isRenamingFunction || extraOptionsFunction !== _function"
        styleClass="p-button-text text-left w-full body-small border-none"
        [label]="_function"
        (click)="addFunction(_function)"
      >
        <p-button
          *ngIf="!isRenamingFunction"
          pRipple
          icon="pi pi-ellipsis-h"
          class="extra-options flex-order-1 flex"
          styleClass="p-button-text p-button-rounded body-small border-none h-1rem w-1rem"
          (click)="showExtraOptions($event, _function)">
        </p-button>
      </p-button>  
      <input
        *ngIf="isRenamingFunction && extraOptionsFunction === _function"
        #searchFunctionInput
        class="shadow-none border-noround border-none border-bottom-1 py-1 body-small surface-primary"
        pInputText
        [(ngModel)]="renamingFunctionStr"
        (keyup)="renameFunction($event)"
        placeholder="Function name"
        type="text" />
    </ng-container>
    <p-menu
      #extraOptionsMenu
      [popup]="true"
      appendTo="body"
      styleClass="toolbar-batch-editor-menu p-0"
      [model]="extraMenuOptions">
    </p-menu>
  </div>
</p-overlayPanel>