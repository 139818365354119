/**
 * InfinitivePlatform API
 * Modular Configurator
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Whether connection is rigid or moveable
 */
export interface ConnectorPlacement { 
    /**
     * Freedom of the rotation
     */
    freedom?: ConnectorPlacement.FreedomEnum;
}
export namespace ConnectorPlacement {
    export type FreedomEnum = 'Fixed' | 'Sliding' | 'Cable';
    export const FreedomEnum = {
        Fixed: 'Fixed' as FreedomEnum,
        Sliding: 'Sliding' as FreedomEnum,
        Cable: 'Cable' as FreedomEnum
    };
}
