
import { Directive, ElementRef, HostListener, Input, forwardRef, Output, EventEmitter, Inject, PLATFORM_ID, NgModule } from '@angular/core';
import { CommonModule, DOCUMENT, isPlatformBrowser } from '@angular/common';
import { DomHandler } from 'primeng/dom';
import { Validator, AbstractControl, NG_VALIDATORS, FormsModule } from '@angular/forms';
//@ts-ignore
import {IREGEX} from 'incr-regex-package';

export const INCREMENTALFILTER_VALIDATOR: any = {
    provide: NG_VALIDATORS,
    useExisting: forwardRef(() => IncrementalFilterDirective),
    multi: true
};

type DefaultMasks = {
    pint: RegExp;
    int: RegExp;
    pnum: RegExp;
    money: RegExp;
    num: RegExp;
    hex: RegExp;
    email: RegExp;
    alpha: RegExp;
    alphanum: RegExp;
};

type SafariKeys = {
    63234: number;
    63235: number;
    63232: number;
    63233: number;
    63276: number;
    63277: number;
    63272: number;
    63273: number;
    63275: number;
};

type Keys = {
    TAB: number;
    RETURN: number;
    ESC: number;
    BACKSPACE: number;
    DELETE: number;
};

const DEFAULT_MASKS: DefaultMasks = {
    pint: /[\d]/,
    int: /[\d\-]/,
    pnum: /[\d\.]/,
    money: /[\d\.\s,]/,
    num: /[\d\-\.]/,
    hex: /[0-9a-f]/i,
    email: /[a-z0-9_\.\-@]/i,
    alpha: /[a-z_]/i,
    alphanum: /[a-z0-9_]/i
};

const KEYS: Keys = {
    TAB: 9,
    RETURN: 13,
    ESC: 27,
    BACKSPACE: 8,
    DELETE: 46
};

const SAFARI_KEYS: SafariKeys = {
    63234: 37, // left
    63235: 39, // right
    63232: 38, // up
    63233: 40, // down
    63276: 33, // page up
    63277: 34, // page down
    63272: 46, // delete
    63273: 36, // home
    63275: 35 // end
};

/**
 * KeyFilter Directive is a built-in feature of InputText to restrict user input based on a regular expression.
 * THIS IS A MIX OF KEYFILTER AND THIS: https://github.com/nurulc/react-rxinput/blob/master/lib/index.js
 * @group Components
 */
@Directive({
    selector: '[infKeyFilter]',
    providers: [INCREMENTALFILTER_VALIDATOR],
    host: {
        class: 'p-element'
    }
})
export class IncrementalFilterDirective implements Validator {
    /**
     * When enabled, instead of blocking keys, input is validated internally to test against the regular expression.
     * @group Props
     */
    @Input() pValidateOnly: boolean | undefined;
    /**
     * Sets the pattern for key filtering.
     * @group Props
     */
    @Input('infKeyFilter') set pattern(_pattern: string | RegExp) {
        this._pattern = _pattern;
        this.regex = (DEFAULT_MASKS as any)[this._pattern as string] || this._pattern;
        this.rx = new IREGEX(this._pattern) as IncrementalRegex;
    }
    get pattern(): string | RegExp {
        return this._pattern;
    }
    /**
     * Emits a value whenever the ngModel of the component changes.
     * @param {(string)} modelValue - Custom model change event.
     * @group Emits
     */
    @Output() ngModelChange: EventEmitter<string> = new EventEmitter<string>();

    /**
     * Emits a hint, changing as the value changes
     */
    @Output() hintChange: EventEmitter<string> = new EventEmitter<string>();

    regex!: RegExp;

    rx: IncrementalRegex;

    _pattern: string | RegExp;

    isAndroid: boolean;

    constructor(@Inject(DOCUMENT) private document: Document, @Inject(PLATFORM_ID) private platformId: any, public el: ElementRef) {
        if (isPlatformBrowser(this.platformId)) {
            this.isAndroid = DomHandler.isAndroid();
        } else {
            this.isAndroid = false;
        }
    }

    isNavKeyPress(e: KeyboardEvent) :Boolean {
        let k = e.keyCode;
        k = DomHandler.getBrowser().safari ? (SAFARI_KEYS as any)[k] || k : k;

        return (k >= 33 && k <= 40) || k == KEYS.RETURN || k == KEYS.TAB || k == KEYS.ESC;
    }

    isSpecialKey(e: KeyboardEvent) {
        let k = e.keyCode || e.charCode;

        return k == 9 || k == 13 || k == 27 || k == 16 || k == 17 || (k >= 18 && k <= 20) || (DomHandler.getBrowser().opera && !e.shiftKey && (k == 8 || (k >= 33 && k <= 35) || (k >= 36 && k <= 39) || (k >= 44 && k <= 45)));
    }

    getKey(e: KeyboardEvent){
        let k = e.keyCode || e.charCode;
        return DomHandler.getBrowser().safari ? (SAFARI_KEYS as any)[k] || k : k;
    }

    getCharCode(e: KeyboardEvent) {
        return e.charCode || e.keyCode || e.which;
    }

    findDelta(value: string, prevValue: string): string {
      return value.substring(this.equalToLength(value, prevValue), value.length);
    }

    equalToLength(a: string, b: string): number {
      const n = Math.min(a.length, b.length);
      for (let i = 0; i < n; i++) {
        if(a[i]!==b[i]) {
          return i;
        }
      }
      return n;
    }

    @HostListener('input', ['$event'])
    onInput(e: KeyboardEvent) {
      if(this.pValidateOnly) {
        return;
      }
      const val = this.el.nativeElement.value;
      const state = this.rx.inputStr();
      // Check if the input value and parser state match
        if (val===state) {
          this.hintChange.emit(this.rx.minChars());
        } else {
          console.log(this.equalToLength(val, state));
          // Find the difference between states
            const inserted = this.findDelta(val, state);
            const removed = this.findDelta(state, val);
            if(!removed) {
              // If nothing is removed we can just increment the state
              this.rx.matchStr(inserted);
            } else {
              // We need to reset the parser and try inserting the whole value
              this.rx = new IREGEX(this._pattern) as IncrementalRegex;
              this.rx.matchStr(val);
            }
            // Get what the parser accepted
            const newState = this.rx.inputStr();
            // If we still have a difference, use just the parser state
            if(val!==newState) {
              this.el.nativeElement.value = newState;
              this.ngModelChange.emit(newState);
            }
            this.hintChange.emit(this.rx.minChars());
        }
    }

    @HostListener('keypress', ['$event'])
    onKeyPress(e: KeyboardEvent) {
        if (this.isAndroid || this.pValidateOnly) {
            return;
        }

        let browser = DomHandler.getBrowser();
        let k = this.getKey(e);

        if (browser.mozilla && (e.ctrlKey || e.altKey)) {
            return;
        } else if (k == 17 || k == 18) {
            return;
        }

        let c = this.getCharCode(e);
        let cc = String.fromCharCode(c);
        let ok = true;

        if (!browser.mozilla && (this.isSpecialKey(e) || !cc)) {
            return;
        }
        ok = this.rx.match(cc);

        if (!ok) {
            e.preventDefault();
        }
    }

    @HostListener('focus')
    focus() {
        this.hintChange.emit(this.rx.minChars());
    }

    @HostListener('blur')
    blur() {
        this.hintChange.emit('');
    }

    validate(c: AbstractControl): { [key: string]: any } | any {
        if (this.pValidateOnly) {
            let value = this.el.nativeElement.value;
            if (value && !this.regex.test(value)) {
                return {
                    validatePattern: false
                };
            }
        }
    }
}

interface IncrementalRegex {
  match( ch : string ): boolean;
  matchStr( str : string) : [ success: boolean, matchCount: number, matchedStr: string];
  inputStr() : string;
  minChars(): string;
}


@NgModule({
  imports: [CommonModule],
  exports: [IncrementalFilterDirective],
  declarations: [IncrementalFilterDirective]
})
export class IncrementalFilterModule {}
