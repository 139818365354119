<div class="fill-v">
  <!-- Displaying Customer Name or "Customer Details" as a fallback -->
  <h2 class="font-medium mb-4" *ngIf="!loading">{{ customer?.user?.name || 'Customer Details' }}</h2>
  <p *ngIf="loading">Loading...</p>

  <!-- User Details Section -->
  <div *ngIf="!loading">
    <p><strong>Email:</strong> {{ customer?.user?.email }}</p>
    <p><strong>Workspace:</strong> {{ customer?.user?.workspace }}</p>
    <p><strong>Roles:</strong> {{ customer?.user?.roles?.join(', ') }}</p>
    <p><strong>Signup Method:</strong> {{ customer?.user?.signup_method }}</p>
    <p><strong>Signup Date:</strong> {{ customer?.user?.signup_date | date }}</p>

    <!-- Displaying User Sessions -->
    <h3 class="font-medium mb-2 mt-4">Sessions</h3>
    <div *ngIf="customer?.user?.sessions?.length > 0">
      <div *ngFor="let session of customer?.user?.sessions">
        <p><strong>Date:</strong> {{ session.date | date }}</p>
        <p><strong>IP:</strong> {{ session.ip }}</p>
        <p><strong>User Agent:</strong> {{ session.userAgent }}</p>
        <p><strong>City:</strong> {{ session.city }}</p>
        <p><strong>Region:</strong> {{ session.region }}</p>
        <p><strong>State:</strong> {{ session.state }}</p>
        <p><strong>Lat/Long:</strong> {{ session.latLong }}</p>
      </div>
    </div>
    <p *ngIf="customer?.user?.sessions?.length === 0">No sessions available.</p>

    <!-- Configurations Table -->
    <h3 class="font-medium mb-2 mt-4">Configurations</h3>
    <table *ngIf="customer?.configurations?.length > 0" class="table-auto w-full">
      <thead>
        <tr>
          <th>Name</th>
          <th>Description</th>
          <th>Product</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let config of customer?.configurations">
          <td>{{ config.data.name }}</td>
          <td>{{ config.data.description }}</td>
          <td>{{ config.data.product }}</td>
        </tr>
      </tbody>
    </table>
    <p *ngIf="!customer?.configurations?.length">No configurations available.</p>

    <!-- Orders Section -->
    <h3 class="font-medium mb-2 mt-4">Orders</h3>
    <table *ngIf="customer?.orders?.length > 0" class="table-auto w-full">
      <thead>
        <tr>
          <th>Order ID</th>
          <th>Date</th>
          <th>Status</th>
          <th>Total</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let order of customer?.orders">
          <td>{{ order.id }}</td>
          <td>{{ order.date | date }}</td>
          <td>{{ order.status }}</td>
          <td>{{ order.total | currency }}</td>
        </tr>
      </tbody>
    </table>
    <p *ngIf="!customer?.orders?.length">No orders available.</p>
  </div>
</div>
