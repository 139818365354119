/**
 * InfinitivePlatform API
 * Modular Configurator
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface ModuleMessage { 
    status?: ModuleMessage.StatusEnum;
    rule?: string;
    message?: string;
}
export namespace ModuleMessage {
    export type StatusEnum = 'Incompatible' | 'Satisfied' | 'Infeasible' | 'Incomplete' | 'OK';
    export const StatusEnum = {
        Incompatible: 'Incompatible' as StatusEnum,
        Satisfied: 'Satisfied' as StatusEnum,
        Infeasible: 'Infeasible' as StatusEnum,
        Incomplete: 'Incomplete' as StatusEnum,
        OK: 'OK' as StatusEnum
    };
}
