import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DividerModule } from 'primeng/divider';
import { ButtonModule } from 'primeng/button';
import { RouterLink } from '@angular/router';


@Component({
  selector: 'inf-orders-overview',
  standalone: true,
  imports: [ButtonModule, DividerModule,RouterLink],
  templateUrl: './orders-overview.component.html',
})
export class OrdersOverviewComponent {

}