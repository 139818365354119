import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Theme } from 'generated-src';

@Component({
  selector: 'inf-theme-keycolors',
  standalone: true,
  templateUrl: './theme-keycolors.component.html',
  imports: [
    CommonModule
  ],
})
export class ThemeKeycolorsComponent {
    @Input() theme:Theme;

    constructor() {}
}
