import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IntegrationService, InstalledIntegration } from '@harmanpa/ng-cae';
import { RouterModule } from '@angular/router';
import { Observable } from 'rxjs';
import { TooltipModule } from 'primeng/tooltip';
import { NgOptimizedImage } from '@angular/common';

@Component({
  selector: 'inf-integrations',
  standalone: true,
  imports: [CommonModule, RouterModule, TooltipModule, NgOptimizedImage],
  templateUrl: './integrations.component.html',
})
export class IntegrationsComponent implements OnInit {

  constructor(private integrationService: IntegrationService) {

  }

  ngOnInit(): void {
    this.integrationService.getInstalledIntegrations().subscribe(integrations => {
      this.integrations = integrations;
      this.images = new Array<string | undefined>(this.integrations.length);
      this.integrations.forEach((ii, i) => {
        if(ii.avatarUrl) {
          this.integrationService.convertAvatarUrl(ii.avatarUrl as string).subscribe(url => this.images[i] = url);
        }
      });
      
    });
  }

  integrationTrackBy(index:number, integration: InstalledIntegration):string {
    return integration.name as string;
  }

  integrations: InstalledIntegration[] = [];
  images: (string | undefined)[] = [];
}
