import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterOutlet } from '@angular/router';
import { SwaggerRequest, SwaggerUIBundle, SwaggerUIStandalonePreset } from "swagger-ui-dist";
import { AuthService } from '@harmanpa/ng-cae';

@Component({
  selector: 'inf-swagger',
  standalone: true,
  imports: [CommonModule, RouterOutlet],
  templateUrl: './swagger.component.html'
})
export class SwaggerComponent {

  /**
   *
   * source: https://swagger.io/docs/open-source-tools/swagger-ui/usage/installation/
   *
   */
  constructor(private auth: AuthService) {

  }

  ngOnInit() {
    SwaggerUIBundle({
      urls: [
        {
          name: 'Infinitive API',
          url: '/api/schema.json'
        }
      ],
      domNode: document.getElementById('swagger-ui'),
      deepLinking: false,
      requestInterceptor: (request: SwaggerRequest) => {
        request['headers'] = Object.assign({}, request['headers'] || {}, { Authorization: 'Bearer ' + this.auth.getToken() });
        console.log(request);
        return request;
      },
      presets: [SwaggerUIBundle['presets'].apis, SwaggerUIStandalonePreset],
      layout: 'StandaloneLayout',
      display: {
        filter: true
      },
    });
  }
}
