<div
  class="align-items-center border-round-sm flex menu-item p-2"
  [class.disabled]="item.disabled"
  (click)="onClick(item)"
  [routerLink]="item.routerLink"
  [routerLinkActive]="markActiveRoute ? 'active' : ''">
  <i
    class="mr-2 menu-item-icon"
    [class]="item.icon"></i>
  <div
    class="flex-1 menu-item-label text-sm"
    *ngIf="item.label">
    {{ item.label | truncate : [30, '...'] }}
  </div>
  <i
    *ngIf="item.items"
    class="ml-2 pi pi-chevron-down"></i>
</div>
<ng-container *ngIf="item.expanded">
  <inf-menu-item
    *ngFor="let childItem of item.items; trackBy: childItemTrackBy"
    [item]="childItem"></inf-menu-item>
</ng-container>
