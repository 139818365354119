/**
 * InfinitivePlatform API
 * Modular Configurator
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface ImageOptions { 
    /**
     * Set rounded corners with given radius
     */
    cornerRadius?: number;
    /**
     * Set fixed height to change aspect ratio
     */
    height?: number;
    /**
     * Convert image to greyscale
     */
    greyscale?: boolean;
    /**
     * Replace this colour with transparent
     */
    transparent?: string;
    /**
     * Approach to use if changing aspect ratio
     */
    reshapeType?: ImageOptions.ReshapeTypeEnum;
}
export namespace ImageOptions {
    export type ReshapeTypeEnum = 'Squeeze' | 'Pad' | 'Crop';
    export const ReshapeTypeEnum = {
        Squeeze: 'Squeeze' as ReshapeTypeEnum,
        Pad: 'Pad' as ReshapeTypeEnum,
        Crop: 'Crop' as ReshapeTypeEnum
    };
}
