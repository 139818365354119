/**
 * InfinitivePlatform API
 * Modular Configurator
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { EnumOption } from './enumOption';

/**
 * Attributes that affect this assembly
 */
export interface Attribute { 
    target?: Attribute.TargetEnum;
    type?: Attribute.TypeEnum;
    id?: string;
    name?: string;
    value?: string;
    options?: Array<EnumOption>;
    quantity?: string;
    minValue?: string;
    maxValue?: string;
    defaultValue?: string;
    units?: string;
}
export namespace Attribute {
    export type TargetEnum = 'Uri' | 'Mesh' | 'Visibility' | 'None';
    export const TargetEnum = {
        Uri: 'Uri' as TargetEnum,
        Mesh: 'Mesh' as TargetEnum,
        Visibility: 'Visibility' as TargetEnum,
        None: 'None' as TargetEnum
    };
    export type TypeEnum = 'Boolean' | 'Enum' | 'String' | 'Quantity';
    export const TypeEnum = {
        Boolean: 'Boolean' as TypeEnum,
        Enum: 'Enum' as TypeEnum,
        String: 'String' as TypeEnum,
        Quantity: 'Quantity' as TypeEnum
    };
}
