import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AvatarComponent } from '../../../shared/components/avatar/avatar.component';
import { MenuItemComponent } from '../../../shared/components/menu-item/menu-item.component';
import { MenuGroup } from '../../../shared/model/menu-group.model';
import { MenuVariants } from '../../../shared/helpers/menu-variants.helper';
import { DividerModule } from 'primeng/divider';
import { AlertService, AuthService, User, UserService } from '@harmanpa/ng-cae';
import { DefaultService } from 'generated-src';
import { MenuItem } from 'primeng/api';


@Component({
  selector: 'inf-user-menu',
  standalone: true,
  imports: [AvatarComponent, CommonModule, DividerModule, MenuItemComponent],
  templateUrl: './user-menu.component.html',
})
export class UserMenuComponent {
  @Input() menuOpen: boolean = false;
  @Output() menuOpenChange = new EventEmitter<boolean>();

  appVersion: string = '';
  generalUserMenu: MenuGroup = MenuVariants.GENERAL_USER_MENU;
  userMenu: MenuGroup = MenuVariants.USER_MENU;
  user: User = { email: '', workspace: ''};
  url: string = '';
  wasInside: boolean = false;

  constructor(
    private alertService: AlertService,
    private api: DefaultService,
    private authService: AuthService,
    private userService: UserService
  ) {}

  ngOnInit():void {
    this.userService.me().subscribe({
      next: user => (this.user = user),
      error: err => this.alertService.error(err),
    });
    this.url = '/api/users/me.png?token=' + this.authService.getToken();
    this.getVersion();
  }

  toggleMenu(): void {
    this.menuOpen = !this.menuOpen;
    this.menuOpenChange.emit(this.menuOpen);
  }

  @HostListener('click')
  click(): void {
    this.wasInside = true;
  }

  @HostListener('document:click')
  hideDatepicker(): void {
    if (!this.wasInside) {
      this.menuOpen = false;
    }
    this.wasInside = false;
  }

  getVersion(): void {
    this.api.getAppVersion().subscribe({
      next: (res: any) => {
        this.appVersion = res['app'];
        if (
          this.generalUserMenu.items.find(
            item => item.id === 'appVersion'
          )
        ) {
          console.log('version exists');
          // no need to add version
        } else {
          this.generalUserMenu.items.push({ label: this.appVersion, id: 'appVersion' });
        }
      },
      error: err => {
        this.alertService.error(err);
      },
    });
  }

  itemTrackBy(index: number, item:MenuItem):string{
    return item.label as string;
  }
}
