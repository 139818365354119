import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonModule } from 'primeng/button';
import { RippleModule } from 'primeng/ripple';
import { InputTextModule } from 'primeng/inputtext';
import { BugReportService } from '@harmanpa/ng-cae';
import { NgOptimizedImage } from '@angular/common';


@Component({
  templateUrl: './hero.component.html',
  selector: 'inf-landing-hero',
  standalone: true,
  imports: [ButtonModule, CommonModule, InputTextModule, NgOptimizedImage, RippleModule],
})
export class LandingHeroComponent {
  constructor(private bugReport: BugReportService) {

  }

  onClickScroll(elementId: string): void {
    console.log(elementId);
    document.getElementById(elementId)?.scrollIntoView({ behavior: "smooth" });
  }

  onRequestDemo(): void {
    this.bugReport.openFlow('vjoqna');
  }
}
