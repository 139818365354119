/**
 * InfinitivePlatform API
 * Modular Configurator
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { CategoryMessage } from './categoryMessage';
import { ModuleMessage } from './moduleMessage';
import { ModuleOption } from './moduleOption';
import { ModuleStatusMessage } from './moduleStatusMessage';

export interface CategoryState { 
    status?: CategoryState.StatusEnum;
    messages?: Array<CategoryMessage>;
    moduleMessages?: { [key: string]: ModuleMessage; };
    statusMessages?: Array<ModuleStatusMessage>;
    options?: Array<ModuleOption>;
}
export namespace CategoryState {
    export type StatusEnum = 'Incomplete' | 'Complete' | 'Invalid' | 'Optional' | 'OK';
    export const StatusEnum = {
        Incomplete: 'Incomplete' as StatusEnum,
        Complete: 'Complete' as StatusEnum,
        Invalid: 'Invalid' as StatusEnum,
        Optional: 'Optional' as StatusEnum,
        OK: 'OK' as StatusEnum
    };
}
