/**
 * InfinitivePlatform API
 * Modular Configurator
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface CriterionScore { 
    /**
     * Whether this criteria is satisfied
     */
    status?: CriterionScore.StatusEnum;
    /**
     * Numerical score
     */
    score?: number;
}
export namespace CriterionScore {
    export type StatusEnum = 'Satisfied' | 'Unsatisfied';
    export const StatusEnum = {
        Satisfied: 'Satisfied' as StatusEnum,
        Unsatisfied: 'Unsatisfied' as StatusEnum
    };
}
