/**
 * InfinitivePlatform API
 * Modular Configurator
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { EvolutionOptions } from './evolutionOptions';

export interface GenerativeOptions { 
    action?: GenerativeOptions.ActionEnum;
    maxSize?: number;
    nextType?: GenerativeOptions.NextTypeEnum;
    options?: EvolutionOptions;
}
export namespace GenerativeOptions {
    export type ActionEnum = 'Increment' | 'Complete' | 'Saturate' | 'Connect' | 'Mutate' | 'Evolve';
    export const ActionEnum = {
        Increment: 'Increment' as ActionEnum,
        Complete: 'Complete' as ActionEnum,
        Saturate: 'Saturate' as ActionEnum,
        Connect: 'Connect' as ActionEnum,
        Mutate: 'Mutate' as ActionEnum,
        Evolve: 'Evolve' as ActionEnum
    };
    export type NextTypeEnum = 'Any' | 'Connected' | 'Intersection';
    export const NextTypeEnum = {
        Any: 'Any' as NextTypeEnum,
        Connected: 'Connected' as NextTypeEnum,
        Intersection: 'Intersection' as NextTypeEnum
    };
}
