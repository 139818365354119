<div id="feature" class="surface-invert-dark px-4 py-8 md:px-6 lg:px-8 flex align-items-center">
  <div class="grid grid-nogutter">
    <div class="col-12 md:col-6 flex align-items-center">
      <div>
        <div class="text-blue body-large mb-3">END-TO-END</div>
        <div class="text-primary heading-xlarge text-semibold mb-5 md:mb-6 lg:mb-6">Customer Choice to Manufacturing</div>
        <div class="grid grid-nogutter pb-3 md:pb-0 lg:pb-0">
          <div class="col-12 md:col-6 md:pr-4 md:pb-4">
            <div class="text-primary body-medium-semibold mb-2">Intuitive and Guided 3D Configuration</div>
            <div class="landing-text-secondary body-small mb-3">
              Automated configurator creation from product CAD data.
              Customise it with your brand.
            </div>
          </div>
          <div class="col-12 md:col-6 md:pr-4 md:pb-4">
            <div class="text-primary body-medium-semibold mb-2">Seamless Integration</div>
            <div class="landing-text-secondary body-small mb-3">
              Integrates with E-commerce, ERP, CRM and all major CAD solutions.
            </div>
          </div>
          <div class="col-12 md:col-6 md:pr-4">
            <div class="text-primary body-medium-semibold mb-2">Generative Modular Design&trade;</div>
            <div class="landing-text-secondary body-small mb-3">
              Offer your clients better products that truly fit their needs through AI and design rules. 
            </div>
          </div>
          <div class="col-12 md:col-6 md:pr-4">
            <div class="text-primary body-medium-semibold mb-2">
              Order Management
            </div>
            <div class="landing-text-secondary body-small mb-3">
              Manufacturing process data and as-configured CAD generated and accessible to production team.
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 md:col-6 flex align-items-center lg:justify-content-end sm:justify-content-center">
      <video class="p-0 col-12 md:col-10 lg:col-10" autoplay loop muted playsinline controls>
        <source  src="/assets/videos/landingVideo.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  </div>
</div>
