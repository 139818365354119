/**
 * InfinitivePlatform API
 * Modular Configurator
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { FeatureTemplate } from './featureTemplate';
import { Source } from './source';

/**
 * Related connector definitions
 */
export interface Connector { 
    /**
     * Connector type name
     */
    name?: string;
    /**
     * Connector type description
     */
    description?: string;
    source?: Source;
    /**
     * Gender of connector, if defined
     */
    gender?: Connector.GenderEnum;
    /**
     * Meta-data for this connector
     */
    meta?: { [key: string]: any; };
    /**
     * Possible holes or other geometric features required
     */
    featureTemplates?: Array<FeatureTemplate>;
}
export namespace Connector {
    export type GenderEnum = 'None' | 'Male' | 'Female';
    export const GenderEnum = {
        None: 'None' as GenderEnum,
        Male: 'Male' as GenderEnum,
        Female: 'Female' as GenderEnum
    };
}
