import { Component } from '@angular/core';

@Component({
  selector: 'inf-wait',
  templateUrl: './wait.component.html',
  standalone: true,
  styles: [],
})
export class WaitComponent {

}
