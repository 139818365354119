<!-- NAVBAR 3-->
<inf-landing-navbar></inf-landing-navbar>
<!-- Hero 2 -->
<inf-landing-hero></inf-landing-hero>
<!-- Feature Block 3 -->
<inf-landing-feature></inf-landing-feature>
<!-- Integrations -->
<!-- <inf-landing-integrations></inf-landing-integrations> -->
<inf-landing-solutions></inf-landing-solutions>
<!-- Logo clouds 2 -->
<inf-landing-logo-clouds></inf-landing-logo-clouds>
<!-- <inf-landing-cta></inf-landing-cta> -->
<!-- CTA 8 -->
<!-- <inf-landing-pricing></inf-landing-pricing> -->
<!-- Pricing block 6 -->
<!-- testimonials -->
<!-- <inf-landing-testimonials></inf-landing-testimonials> -->
<!-- Team section 1 -->
<inf-landing-team></inf-landing-team>
<!-- CTA Block 3 -->
<inf-landing-contact></inf-landing-contact>
<!-- Footer block 3 -->
<inf-landing-footer></inf-landing-footer>
