<div class="fill-v">
  <div class="align-items-center flex justify-content-between">
    <h2 class="font-medium mb-2">Orders</h2>
    <button
      class="ml-auto p-button-sm p-button-outlined"
      icon="pi pi-sync"
      loadingIcon="pi pi-sync pi-spin"
      iconPos="left"
      label="Sync External"
      pTooltip="Synchronise data from external integrations"
      tooltipPosition="bottom"
      pButton
      [loading]="ordersSyncing"
      (click)="syncOrders()"></button>
  </div>
  <div class="font-medium subtitle mb-6">OVERVIEW OF E-COMMERCE</div>

  
  <div class="mb-4">
    <button
      class="p-button-sm"
      icon="pi pi-sliders-v"
      iconPos="left"
      label="Workflow"
      pButton
      pTooltip="View or Edit Order Workflow"
      tooltipPosition="bottom"
      [routerLink]="['/workflow']"></button>
    <button
      class="p-button-sm ml-2"
      icon="pi pi-users"
      iconPos="left"
      label="Customers"
      pButton
      pTooltip="View activity by customers"
      tooltipPosition="bottom"
      [routerLink]="['/customers']"></button>
  </div>

  <inf-orders-status
    [dashboard]="true"
    class="mb-4"></inf-orders-status>

  <div class="card flex flex-column">

      <!-- <inf-search-system
        class="mb-4"
        [filters]="filters"></inf-search-system> -->

      <p-table *ngIf="!loading"
        #dtorders
        class="mb-5 flex-1"
        dataKey="id"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        [globalFilterFields]="['data.firstName', 'data.lastName','id','data.customerID',
        'data.email','data.orderStatus','data.deliveryStatus' ]"
        [paginator]="orders.length != 0"
        [rowsPerPageOptions]="[10, 25, 50]"
        [rows]="rowsNumber"
        [scrollable]="false"
        [showCurrentPageReport]="true"
        [value]="orders">
        <ng-template
          pTemplate="caption"
          *ngIf="includeSearch">
          <inf-search-system class="mb-4"></inf-search-system>
        </ng-template>
        <ng-template pTemplate="header">
          <tr>
            <th
              style="width: 4rem"
              *ngIf="selectOrders">
              <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
            </th>
            <th>Customer</th>
            <th>Created</th>
            <th>Last Edited</th>
            <th>Order ID</th>
            <th>Order Status</th>
            <!-- <th>Delivery Status</th> -->
            <th></th>
          </tr>
        </ng-template>
        <ng-template
          let-value
          pTemplate="body" >
          <tr>
            <td *ngIf="selectOrders">
              <p-tableCheckbox [value]="value"></p-tableCheckbox>
            </td>
            <td>{{ value.owner }}</td>
            <td>{{ value.lastEdited | date : 'dd MMMM yyyy' }}</td>
            <td>{{ value.lastEdited | date : 'dd MMMM yyyy' }}</td>
            <td>{{ value.id }}</td>
            <td>{{ value.data.orderStatus }}</td>

            <!-- <td>{{ value.data.deliveryStatus }}</td> -->
            <td>
              <div class="flex gap-2">
                <button
                  class="flex-shrink-0 p-button-text p-button-rounded"
                  icon="pi pi-pencil"
                  pButton
                  [routerLink]="['/orders', value.id]"
                ></button>
                <button
                  class="flex-shrink-0 p-button-text p-button-rounded"
                  icon="pi pi-ellipsis-v"
                  pButton></button>
              </div>
            </td>
          </tr>
        </ng-template>
      </p-table>

    <inf-empty-state
      *ngIf="!orders.length"
      src="/assets/images/empty-states/orders.svg"
      subtitle="No Active Orders"
      title="Active orders linked to your account will be listed here"></inf-empty-state>
  </div>
</div>
