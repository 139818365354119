/**
 * InfinitivePlatform API
 * Modular Configurator
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * An event from activating a short code
 */
export interface ShortCodeEvent { 
    /**
     * The action of the event
     */
    action?: ShortCodeEvent.ActionEnum;
    /**
     * The user who used the short code
     */
    user?: string;
    /**
     * The duplicate id if applicable
     */
    duplicateId?: string;
}
export namespace ShortCodeEvent {
    export type ActionEnum = 'GrantedReadAccess' | 'GrantedWriteAccess' | 'Duplicated';
    export const ActionEnum = {
        GrantedReadAccess: 'GrantedReadAccess' as ActionEnum,
        GrantedWriteAccess: 'GrantedWriteAccess' as ActionEnum,
        Duplicated: 'Duplicated' as ActionEnum
    };
}
