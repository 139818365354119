import { bootstrapApplication } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideHttpClient } from '@angular/common/http';
import {TitleStrategy, provideRouter, withInMemoryScrolling, withPreloading, PreloadAllModules} from '@angular/router';
import { AppComponent } from './app/app.component';
import { appRoutes } from './app/app.routes';
import {
  AuthModule,
  GeometryService,
  GleapToken,
  LegalRoutingModule,
  MATERIALFACTORY,
  MESHFACTORY,
  NgCaeModule,
  PatchworkAPI,
  RendererService,
} from '@harmanpa/ng-cae';
import { importProvidersFrom } from '@angular/core';
import { DefaultService } from 'generated-src';
import { NgxPermissionsModule } from 'ngx-permissions';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { ViewerMeshFactory } from './app/products/product-editor/product-editor-viewer/viewer-mesh-factory';
import { ViewerMaterialFactory } from './app/products/product-editor/product-editor-viewer/viewer-material-factory';
import 'zone.js';
import { TemplatePageTitleStrategy } from './app/shared/services/notifications-title.service';

bootstrapApplication(AppComponent, {
  providers: [
    { provide: PatchworkAPI, useClass: DefaultService },
    { provide: GleapToken, useValue: 'zpq9CPCZv5xSfhJd2AOcSglR28TB8B6b' },
    {
      provide: MESHFACTORY,
      useClass: ViewerMeshFactory,
      deps: [GeometryService]
    },
    {
      provide: MATERIALFACTORY,
      useClass: ViewerMaterialFactory,
      deps: [RendererService],
    },
    {
      provide: TitleStrategy,
      useClass: TemplatePageTitleStrategy
    },
    importProvidersFrom(
      LoadingBarRouterModule,
      LoadingBarHttpClientModule,
      NgxPermissionsModule.forRoot(),
      NgCaeModule.forRoot({
        userflow: {
          enableSignup: false,
          roleLogin: {},
          login: {
            enablePassword: true,
            providerLogin: {
              github: { name: ' Github account' },
              google: { name: ' Google account' },
              onshape: { name: ' Onshape account' },
              autodesk: { name: ' Autodesk account' },
            },
          },
          leftPanel: {
            title: 'Infinitive Platform',
            subtitle: 'The Future of Choice.',
          },
          rightPanel: {
            title: 'Welcome back',
            subtitle: 'Welcome back! Please enter your details.',
          },
        },
        types: {},
        units: [],
      }),
      LegalRoutingModule,
      AuthModule.forRoot({
        baseUrl: '/api',
        providers: {
          onshape: {
            name: 'onshape',
            url: '/auth/onshape',
            authorizationEndpoint: 'https://oauth.onshape.com/oauth/authorize',
            oauthType: '2.0',
            popupOptions: { width: 600, height: 600 },
          },
          github: {
            name: 'github',
            scope: ['repo', 'gist', 'user'],
          },
          autodesk: {
            name: 'autodesk',
            url: '/auth/autodesk',
            authorizationEndpoint:
              'https://developer.api.autodesk.com/authentication/v2/authorize',
            oauthType: '2.0',
            popupOptions: { width: 600, height: 600 },
            scope: [
              'data:read',
              'data:write',
              'data:create',
              'data:search',
              'bucket:create',
              'bucket:read',
              'bucket:update',
              'bucket:delete',
              'code:all',
              'account:read',
              'account:write',
              'user-profile:read',
              'viewables:read',
            ],
            scopeDelimiter: ' ',
          },
          google: {
            url: '/auth/google',
          },
        },
      }),
    ),
    provideAnimations(),
    provideHttpClient(),
    provideRouter(appRoutes,
      withInMemoryScrolling({ scrollPositionRestoration: 'enabled', anchorScrolling: 'enabled' }),
      withPreloading(PreloadAllModules)
    )
  ],
});
