<ng-container *ngIf="type && id && currentVersion">
  <span class="p-buttonset mr-5">
    <p-button (click)="undo()"
              [disabled]="!undoVersion"
              styleClass="p-button-text p-button-rounded" icon="fa-light fa-rotate-left" pTooltip="Undo" tooltipPosition="bottom"/>
    <p-button (click)="op.toggle($event)" styleClass="p-button-text p-button-rounded" icon="fa-light fa-code-branch" pTooltip="Show versions" tooltipPosition="bottom"/>
    <p-button (click)="redo()"
              [disabled]="!redoVersions || redoVersions.length==0"
              styleClass="p-button-text p-button-rounded" icon="fa-light fa-rotate-right" pTooltip="Redo" tooltipPosition="bottom"/>
  </span>
  <p-overlayPanel #op>
    <div class="flex flex-column gap-3 w-25rem">
      <cae-mermaid [spec]="spec" [config]="{'gitGraph': {'showBranches': false}}"></cae-mermaid>
    </div>
  </p-overlayPanel>
</ng-container>
