import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterOutlet } from '@angular/router';

@Component({
  selector: 'inf-subscriptions',
  standalone: true,
  imports: [CommonModule, RouterOutlet],
  templateUrl: './subscriptions.component.html',
})
export class SubscriptionsComponent {

}
