/**
 * InfinitivePlatform API
 * Modular Configurator
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Vector3D } from './vector3D';

/**
 * Orientation of this module
 */
export interface ModuleOrientation { 
    /**
     * Freedom of the rotation
     */
    freedom?: ModuleOrientation.FreedomEnum;
    axis?: Vector3D;
    /**
     * Angles of rotation allowed, if applicable
     */
    angles?: Array<number>;
}
export namespace ModuleOrientation {
    export type FreedomEnum = 'Fixed' | 'Free' | 'Constrained' | 'Limited';
    export const FreedomEnum = {
        Fixed: 'Fixed' as FreedomEnum,
        Free: 'Free' as FreedomEnum,
        Constrained: 'Constrained' as FreedomEnum,
        Limited: 'Limited' as FreedomEnum
    };
}
