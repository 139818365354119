<div class="flex px-3 py-2 justify-content-between status-box ">
  <div class="flex-1">
    <div class="body-xtra-small text-secondary" [ngStyle]="{'margin-bottom':'2px'}">{{ status | titlecase}}</div>
    <h4 class="body-large-semibold mb-1" >{{ info }}</h4>
<!--    <p-chart type="bar" width="75" height="50" [data]="basicData" [options]="basicOptions" />-->
    <div class="body-small text-secondary">
      <span class="count body-small-semibold">{{ count }} new </span>since last visit
    </div>
  </div>
  <inf-icon class="mt-2" [type]="status"
    [icon]="icon"
    ></inf-icon>
</div>
