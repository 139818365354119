/**
 * InfinitivePlatform API
 * Modular Configurator
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { MaterialColor } from './materialColor';
import { MaterialCubeTexture } from './materialCubeTexture';
import { MaterialTexture } from './materialTexture';
import { OtherMap } from './otherMap';
import { Vector2D } from './vector2D';

export interface MaterialStyling { 
    type?: MaterialStyling.TypeEnum;
    /**
     * Room Environment
     */
    roomEnvironment?: boolean;
    mapScale?: Vector2D;
    mapOffset?: Vector2D;
    /**
     * edgeAngle
     */
    edgeAngle?: number;
    /**
     * rotation
     */
    rotation?: number;
    color?: MaterialColor;
    /**
     * Middle Colors Map
     */
    middleColorsMap?: Array<MaterialColor>;
    /**
     * transparent
     */
    transparent?: boolean;
    /**
     * offset
     */
    offset?: boolean;
    replaceTransparency?: MaterialColor;
    map?: MaterialTexture;
    bottomMap?: MaterialTexture;
    middleMap?: MaterialTexture;
    /**
     * cuttedObject
     */
    cuttedObject?: boolean;
    envMap?: MaterialCubeTexture;
    alphaMap?: MaterialTexture;
    aoMap?: MaterialTexture;
    emissive?: MaterialColor;
    /**
     * metalness
     */
    metalness?: number;
    /**
     * roughness
     */
    roughness?: number;
    /**
     * emissiveIntensity
     */
    emissiveIntensity?: number;
    /**
     * envMapIntensity
     */
    envMapIntensity?: number;
    /**
     * displacementScale
     */
    displacementScale?: number;
    normalScale?: Vector2D;
    displacementMap?: MaterialTexture;
    normalMap?: MaterialTexture;
    metalnessMap?: MaterialTexture;
    roughnessMap?: MaterialTexture;
    /**
     * reflectivity
     */
    reflectivity?: number;
    /**
     * refractionRatio
     */
    refractionRatio?: number;
    /**
     * otherMaps
     */
    otherMaps?: { [key: string]: OtherMap; };
    specular?: MaterialColor;
    /**
     * shininess
     */
    shininess?: number;
    /**
     * clearcoat
     */
    clearcoat?: number;
    /**
     * clearcoatRoughness
     */
    clearcoatRoughness?: number;
    /**
     * transmission
     */
    transmission?: number;
    /**
     * opacity
     */
    opacity?: number;
}
export namespace MaterialStyling {
    export type TypeEnum = 'Basic' | 'Phong' | 'Standard' | 'Physical';
    export const TypeEnum = {
        Basic: 'Basic' as TypeEnum,
        Phong: 'Phong' as TypeEnum,
        Standard: 'Standard' as TypeEnum,
        Physical: 'Physical' as TypeEnum
    };
}
