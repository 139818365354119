import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonModule } from 'primeng/button';
import { RouterLink } from '@angular/router';
import { ProgressBarModule } from 'primeng/progressbar';
import { LettersAvatarComponent } from '../letters-avatar/letters-avatar.component';
import { AlertService, Workspace, WorkspaceService } from '@harmanpa/ng-cae';
import { TooltipModule } from 'primeng/tooltip';

@Component({
  selector: 'inf-pricing',
  standalone: true,
  imports: [ButtonModule, CommonModule,LettersAvatarComponent, ProgressBarModule,RouterLink,TooltipModule],
  templateUrl: './pricing.component.html',
})
export class PricingComponent {
  @Input() description: string = '';
  @Input() features: string[] = [];
  @Input() includes: string = '';
  @Input() name: string = '';
  @Input() pricing: string = '';
  currentWorkspace: Workspace;

  constructor( private alertService: AlertService,
  private workspaceService: WorkspaceService) {}

  ngOnInit():void {
    this.workspaceService.getWorkspace().subscribe({
      next: ws => {
        this.currentWorkspace = ws;
      },
      error: err => this.alertService.error(err)
    });
  }
}

