import { Component, Input, SimpleChange } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BadgeModule } from 'primeng/badge';
import { FormsModule } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { ColorPickerModule } from 'primeng/colorpicker';
import {
  AlertService,
  LogService,
  Theme,
  ThemeProperties,
  ThemeService,
  VersionedTheme,
  Workspace,
  WorkspaceService,
} from '@harmanpa/ng-cae';
import { cloneDeep } from 'lodash-es';
import {
  argbFromHex,
  themeFromSourceColor,
  applyTheme,
  hexFromArgb,
  Theme as themeMCU,
  rgbaFromArgb,
  TonalPalette
} from '@material/material-color-utilities';
import { DialogModule } from 'primeng/dialog';
import _ from 'lodash';
import { ThemeKeycolorsComponent } from './theme-keycolors/theme-keycolors.component';
import { TabViewModule } from 'primeng/tabview';
import { ThemeImagesComponent } from './theme-images/theme-images.component';
import { ThemeFeaturesComponent } from './theme-features/theme-features.component';
import { defaultTheme } from 'src/app/configurators/settings/custom-themes/defaultTheme';
import { ThemePresetComponent } from './theme-preset/theme-preset.component';
import { mergeMap, switchMap } from 'rxjs';
// import { Variant } from '@material/material-color-utilities/scheme/variant';
@Component({
  selector: 'inf-theme-generator',
  standalone: true,
  templateUrl: './theme-generator.component.html',
  imports: [
    BadgeModule,
    ButtonModule,
    CommonModule,
    DialogModule,
    FormsModule,
    InputTextModule,
    ColorPickerModule,
    ThemePresetComponent,
    ThemeKeycolorsComponent
  ],
})
export class ThemeGenerator {
  @Input() management: boolean=false;
  themes: VersionedTheme[] = [];
  selectedTheme: Theme;
  selectedPreset: number = -1;
  seed: string = "#006EB8";
  loading: boolean = false;
  theme: themeMCU;
  keyMainColours: {primary: string, secondary: string, tertiary: string}
  lightTheme: Object;
  darkTheme: Object;
  primaryColorPalette: { [key: string]: number };
  secondaryColorPalette: { [key: string]: number };
  tertiaryColorPalette: { [key: string]: number };
  errorColorPalette: { [key: string]: number };
  neutralColorPalette: { [key: string]: number };
  neutralVariantColorPalette: { [key: string]: number };

  togglePrimary: boolean = false;
  toggleSecondary: boolean = false;
  toggleTertiary: boolean = false;

  hexregex = /^#?([A-Fa-f0-9]{6})$/;

  visible: boolean = false;
  themeName:string;

  constructor(
    private alertService: AlertService,
    private themeService: ThemeService,
    public workspaceService: WorkspaceService,
    public log: LogService
  ) {}

  ngOnInit(): void {
    //get current theme from workspace
    this.loading = true;
    this.themeService.getActiveTheme(defaultTheme).subscribe((theme: Theme) => {
        if(theme){
          this.selectedTheme = cloneDeep(theme);
          this.keyMainColours = {
            primary: this.selectedTheme.properties?.light?.['primary'] as string,
            secondary: this.selectedTheme.properties?.light?.['secondary'] as string,
            tertiary: this.selectedTheme.properties?.light?.['tertiary'] as string,
          }
          this.seed = this.selectedTheme.properties?.light?.['primary'] as string;
        }else{
          this.selectedTheme = cloneDeep(defaultTheme);
          this.seed = this.selectedTheme.properties?.light?.['primary'] as string;
        }
        this.loading = false;
      });
    //get public themes from theme service
    this.themeService.getAllThemes().subscribe(themes => {
        this.themes = themes.filter(theme => (theme.permissions as unknown as {publicDocument: boolean})?.publicDocument === true);
        console.log('themes',this.themes);
      });

    this.createThemeFromHex(defaultTheme.properties?.light?.['primary'] as string);
  }
  ////////////////////////////////////////////
  //REGISTER NEW PUBLIC THEMES
  showDialog() {
    this.visible = true;
  }
  addNewTheme():void{
    this.visible = false;
  //   if (this.hexregex.test(this.seed)) {
  //     this.createThemeFromHex(this.seed);
  //     this.createLightAndDarkSchemes();
  //     this.createPaletteFromTheme(this.theme.palettes);
  //     this.selectedTheme = this.createConfiguratorTheme();
  //     //reset preset
  //     this.selectedPreset = -1;
  //   }
  //
  // CHANGE registeredTheme for the theme you want to register if there is a file. Otherwise change Seed in UI.
  //
   const registeredTheme = Object.assign({}, this.selectedTheme, {name: this.themeName});
   this.themeService.registerTheme(registeredTheme, true).subscribe(res => this.log.debug(res));
  }
  ///////////////////////////////////////////

  /***
   * Generate Surface Values for light and dark theme. These are not generated directly by GetSchemeProps method
   * It uses specific tones from the palette ( getPaletteTones() ) to set the Surface Values
   */
  addSurfaces(corePalettes:{ [key:string]: { [key: string]: number } }): { [key: string]: { [key: string]: string } } {
    return {
      light: {
        surfaceContainerLowest:  corePalettes['neutral']['tone100'].toString(),
        surfaceContainerLow: corePalettes['neutral']['tone096'].toString(),
        surfaceContainer: corePalettes['neutral']['tone094'].toString(),
        surfaceContainerHigh: corePalettes['neutral']['tone092'].toString(),
        surfaceContainerHighest: corePalettes['neutral']['tone090'].toString(),
      },
      dark: {
        surfaceContainerLowest: corePalettes['neutral']['tone004'].toString(),
        surfaceContainerLow: corePalettes['neutral']['tone010'].toString(),
        surfaceContainer: corePalettes['neutral']['tone012'].toString(),
        surfaceContainerHigh: corePalettes['neutral']['tone017'].toString(),
        surfaceContainerHighest: corePalettes['neutral']['tone022'].toString(),
      },
    };
  }

  /***
   * Saves the selected Theme for the current workspace to DB
   * @param [fromPreset=false] this flag is used to save the current selected theme IF the selected theme is a preset (public theme)
   */
  changeSelectedTheme(fromPreset=false): void {
    this.log.debug('selectedTheme', this.selectedTheme);
    this.workspaceService.getWorkspace().pipe(
      switchMap((ws: Workspace) => 
        this.themeService.getTheme(ws.themeId as string).pipe(
          switchMap(theme => {
            // Create a new theme object instead of mutating the original
            const updatedTheme = {
              ...theme,
              name: this.selectedTheme.name,
              seed: this.selectedTheme.seed,
              properties: this.selectedTheme.properties
            };
            
            // Return the updated observable from the updateTheme call
            return this.themeService.updateTheme(ws.themeId as string, updatedTheme);
          })
        )
      )
    ).subscribe(
      result => {
        this.log.info('Theme updated successfully:', result);
      },
      error => {
        this.log.error('Error updating theme:', error);
      }
    );
  }

  createPaletteFromTheme(palettes: any): { [key:string]: { [key: string]: number } } {
    const corePaletteColors: corePaletteColors = {
      primary: palettes.primary.keyColor.argb,
      secondary: palettes.secondary.keyColor.argb,
      tertiary: palettes.tertiary.keyColor.argb,
      neutral: palettes.neutral.keyColor.argb,
      neutralVariant: palettes.neutralVariant.keyColor.argb,
      error: palettes.error.keyColor.argb,
    };

    // let colorPaletteTones:ColorPalettesGroup = {};
    return Object.fromEntries(Object.entries(corePaletteColors).map(corecolour=> [corecolour[0], this.getPaletteTones(corecolour[1])]));
  }

  createThemeFromHex(hex: string): void {
    this.seed =  hex;
    // Get the theme from a hex color
    this.theme = themeFromSourceColor(argbFromHex(hex), [
      {
        name: 'custom-1',
        value: argbFromHex('#ff0000'),
        blend: true,
      },
    ]);

    // this.log.debug('createThemeFromHex', JSON.stringify(this.theme));
  }

  getPaletteTones(argb: number): { [key: string]: number } {
    const color = TonalPalette.fromInt(argb);
    const tonePalette = {
      tone100: color.tone(100),
      tone099: color.tone(99),
      tone098: color.tone(98),
      tone096: color.tone(96),
      tone095: color.tone(95),
      tone094: color.tone(94),
      tone092: color.tone(92),
      tone090: color.tone(90),
      tone080: color.tone(80),
      tone070: color.tone(70),
      tone060: color.tone(60),
      tone050: color.tone(50),
      tone040: color.tone(40),
      tone035: color.tone(35),
      tone030: color.tone(30),
      tone025: color.tone(25),
      tone022: color.tone(22),
      tone020: color.tone(20),
      tone017: color.tone(17),
      tone015: color.tone(15),
      tone012: color.tone(12),
      tone010: color.tone(10),
      tone005: color.tone(5),
      tone004: color.tone(4),
      tone000: color.tone(0),
    };
    return this.transformArgbToHex(tonePalette);
  }


  isArgb(value: number): boolean {
    return typeof value === 'number' && value.toString().length === 10;
  }

  /***
   * To help the UI update the selected theme -> apply the border around the preset theme
   * Save the index of the preset theme
   */
  selectPreset(index: number): void {
    this.selectedPreset = index;
  }

  /***
   * Sets the selected theme preset as current and call changeSelectedTheme to save it to DB.
   */
  setPreset(): void {
    if(this.selectedPreset !== -1){
      this.themeService.setTheme(this.themes[this.selectedPreset].id as string);
      this.selectedTheme = cloneDeep(
        this.themes[this.selectedPreset].document as Theme
      );
      this.getKeyMainColours(this.selectedTheme.seed ? this.selectedTheme.seed : this.selectedTheme.properties?.light?.['primary'] as string)
      this.changeSelectedTheme(true);
    }else{
      this.alertService.warning('Select a theme from the list.', 'No theme selected');
    }
  }

  /***
   * Handle changes on input value for seed and generate theme.
   * @param seed hex value from which generate theme
   */
  themeModelChanged(seed: string): void {
    if (this.hexregex.test(seed)) {
      this.createThemeFromHex(seed);
      this.seed = seed;
      //TODO:from keyColor in TonalPalette extract colors
      let darkandlightschemes = this.createLightAndDarkSchemes();
      const coreColorPalettes = this.createPaletteFromTheme(this.theme.palettes);
      this.selectedTheme = this.createConfiguratorTheme(darkandlightschemes, coreColorPalettes);
      this.getKeyMainColours(this.selectedTheme.properties?.light?.['primary'] as string);
      //reset preset
      this.selectedPreset = -1;
    }
  }



  transformArgbToHex(obj: any): any {
    for (const key in obj) {
      if (typeof obj[key] === 'object') {
        // Recursively process nested objects
        this.transformArgbToHex(obj[key]);
      } else if (this.isArgb(obj[key])) {
        // Convert number to hex
        obj[key + 'RGB'] =
          rgbaFromArgb(obj[key]).r +
          ',' +
          rgbaFromArgb(obj[key]).g +
          ',' +
          rgbaFromArgb(obj[key]).b;
        obj[key] = hexFromArgb(obj[key]);
      }
    }
    return obj;
  }

  /**
   * Transforms argb values from scheme properties to hex and RGB values.
   */
  createLightAndDarkSchemes(): string[] {
    return Object.values(this.theme.schemes).map(scheme=> this.transformArgbToHex(scheme.toJSON()));
  }

  createConfiguratorTheme(dlschemes: string[], corePalettes:{ [key:string]: { [key: string]: number } }): Theme {
    //add extra surfaces for states and elevation levels
    let surfaces = this.addSurfaces(corePalettes);
    return {
      name: this.themeName ? this.themeName : Date.now() + 'configuratorTheme',
      seed: hexFromArgb(this.theme.source).toString(),
      properties: {
        light: Object.assign(
          dlschemes[0] as ThemeProperties,
          surfaces['light']
        ),
        dark: Object.assign(
          dlschemes[1] as ThemeProperties,
          surfaces['dark']
        ),
      },
    };
  }

  toggleAdvanced(keycolour: string):void{
    switch(keycolour){
      case 'primary':
        this.togglePrimary = !this.togglePrimary;
        break
      case 'secondary':
        this.toggleSecondary = !this.toggleSecondary;
        break
      case 'tertiary':
        this.toggleTertiary = !this.toggleTertiary;
        break
    }
  }

  advancedGenerator(theme: Theme, colour:string, coreColour:string):Theme{
    this.getKeyMainColours(colour,coreColour);
    if(this.hexregex.test(colour)){
      const tonal_palette = this.getPaletteTones(argbFromHex(colour));
      const colourCap = coreColour.replace(/^\w/, (c) => c.toUpperCase());
      const lightprops = Object.fromEntries([
          [coreColour, tonal_palette['tone040']],
          [coreColour + 'RGB', tonal_palette['tone040RGB']],
          ['on'+colourCap, tonal_palette['tone100']],
          ['on'+colourCap + 'RGB', tonal_palette['tone100RGB']],
          [coreColour+'Container', tonal_palette['tone090']],
          [coreColour + 'ContainerRGB', tonal_palette['tone090RGB']],
          ['on'+colourCap+'Container', tonal_palette['tone010']],
          ['on'+colourCap + 'ContainerRGB', tonal_palette['tone010RGB']],
          ['inverse'+colourCap, tonal_palette['tone080']],
          ['inverse'+colourCap + 'RGB', tonal_palette['tone080RGB']]
      ]);
      const darkprops = Object.fromEntries([
        [coreColour, tonal_palette['tone080']],
        [coreColour + 'RGB', tonal_palette['tone080RGB']],
        ['on'+colourCap, tonal_palette['tone020']],
        ['on'+colourCap + 'RGB', tonal_palette['tone020RGB']],
        [coreColour+'Container', tonal_palette['tone030']],
        [coreColour + 'ContainerRGB', tonal_palette['tone030RGB']],
        ['on'+colourCap+'Container', tonal_palette['tone090']],
        ['on'+colourCap+ 'ContainerRGB', tonal_palette['tone090RGB']],
        ['inverse'+colourCap, tonal_palette['tone040']],
        ['inverse'+colourCap + 'RGB', tonal_palette['tone040RGB']]
        ]);

      theme.properties = {light:Object.assign({}, theme.properties?.light,lightprops), dark:  Object.assign({}, theme.properties?.dark,darkprops) };
      this.selectedTheme = this.createCustomColorConfiguratorTheme(theme);
      return this.selectedTheme;
    }else{
      this.log.info('This string is not a valid HEX')
      return this.selectedTheme;
    }
  }


  createCustomColorConfiguratorTheme(theme:Theme): Theme {
    //add extra surfaces for states and elevation levels
    const coreColorPalettes = this.createPaletteFromTheme(this.theme.palettes);
    let surfaces = this.addSurfaces(coreColorPalettes);
    return {
      name: this.themeName ? (Date.now()+this.themeName) : Date.now() + `configuratorTheme`,
      seed: theme.seed || '',
      properties: {
        light: Object.assign(
          theme.properties?.light as ThemeProperties,
          surfaces['light']
        ),
        dark: Object.assign(
          theme.properties?.dark as ThemeProperties,
          surfaces['dark']
        ),
      },
    };
  }

  getKeyMainColours(colour:string, coreColour:string | boolean = false):void{
    if(typeof coreColour == 'string'){
      switch(coreColour){
      case 'primary':
        this.keyMainColours.primary = colour ;
        this.seed = colour;
        break
      case 'secondary':
        this.keyMainColours.secondary = colour ;
        break
      case 'tertiary':
        this.keyMainColours.tertiary = colour ;
        break
      }
    }else{
      this.keyMainColours = {
        primary: this.selectedTheme.properties?.light?.['primary'] as string,
        secondary: this.selectedTheme.properties?.light?.['secondary'] as string,
        tertiary: this.selectedTheme.properties?.light?.['tertiary'] as string,
      }
    }

  }
}

interface corePaletteColors {
  [key:string]: number
}

interface ColorPalettesGroup {
  [key:string]: {[key:string]:number};
}

enum Variant {
  MONOCHROME = 0,
  NEUTRAL = 1,
  TONAL_SPOT = 2,
  VIBRANT = 3,
  EXPRESSIVE = 4,
  FIDELITY = 5,
  CONTENT = 6,
  RAINBOW = 7,
  FRUIT_SALAD = 8
}
