import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterLinkActive, RouterLinkWithHref } from '@angular/router';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'inf-horizontal-menu-item',
  standalone: true,
  imports: [CommonModule, RouterLinkActive, RouterLinkWithHref],
  templateUrl: './horizontal-menu-item.component.html',
})
export class HorizontalMenuItemComponent {
  @Input() item: MenuItem = {};
}
