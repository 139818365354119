<div class="m-4">
  <h1>AUTH PAGE</h1>
  <!-- <inf-oauth-page></inf-oauth-page> -->
  <h1>Temp Card</h1>
  <div class="flex flex-column gap-3">
    <inf-temp-card
      [title]="'Well done!'"
      [message]="
        'To start work with Infinitive, add your first product in Products page'
      "
      [icon]="'pi pi-bell'"
      [closeBtn]="true"
      [actionBtn]="true"
      [actionBtnLabel]="'Create'">
    </inf-temp-card>
    <inf-temp-card
      [title]="'Well done!'"
      [message]="
        'To start work with Infinitive, add your first product in Products page'
      "
      [icon]="'pi pi-bell'"
      [closeBtn]="false"
      [actionBtn]="true"
      [actionBtnLabel]="'Create'">
    </inf-temp-card>

    <inf-temp-card
      [title]="'Well done!'"
      [message]="
        'To start work with Infinitive, add your first product in Products page'
      "
      [icon]="'pi pi-bell'"
      [closeBtn]="false"
      [actionBtn]="false">
    </inf-temp-card>
    <inf-temp-card
      [type]="'float'"
      [title]="'Well done!'"
      [message]="'To start work with Infinitive, add your first product in Products page'"
      [icon]="'pi pi-bell'"
      [closeBtn]="false"
      [actionBtn]="false">
    </inf-temp-card>
    <inf-temp-card
      [type]="'float'"
      [message]="'To start work with Infinitive, add your first product in Products page'"
      [icon]="'pi pi-bell'"
      [closeBtn]="true"
      [actionBtn]="true"
      [actionBtnLabel]="'Settings'">
    </inf-temp-card>
    <inf-info-message
    [icon]="'pi-bell'"
    [arrangement]="'vertical'"
    [title]="'Confirmation'"
    [message]="'Are you sure you want to leave the workspace?'"
    [actions]="2"
    [actionBtn1Label]="'Cancel'"
    [actionBtn2Label]="'Leave'"></inf-info-message>
    <inf-info-message
    [icon]="'pi-bell'"
    [arrangement]="'vertical'"
    [title]="'Confirmation'"
    [message]="'Are you sure you want to leave the workspace?'"
    [actions]="1"
    [actionBtn1Label]="'Cancel'"
    [actionBtn2Label]="'Leave'"></inf-info-message>
    <inf-info-message
    [icon]="'pi-bell'"
    [arrangement]="'horizontal'"
    [title]="'Confirmation'"
    [message]="'Are you sure you want to leave the workspace?'"
    [actions]="2"
    [actionBtn1Label]="'Cancel'"
    [actionBtn2Label]="'Leave'"></inf-info-message>
  </div>

  <h1>Avatar</h1>
  <div class="card">
    <div class="flex gap-2 m-2">
      <inf-avatar
        size="large"
        src="../../assets/images/icons/icon.png"></inf-avatar>
      <inf-avatar
        initials="XP"
        size="large"></inf-avatar>
      <inf-avatar
        size="large"
        label="Xenia"
        src="../../assets/images/icons/icon.png"></inf-avatar>
      <inf-avatar
        initials="XP"
        size="large"
        label="Xenia"></inf-avatar>
    </div>
    <div class="flex gap-2 m-2">
      <inf-avatar src="../../assets/images/icons/icon.png"></inf-avatar>
      <inf-avatar initials="XP"></inf-avatar>
      <inf-avatar
        label="Xenia"
        src="../../assets/images/icons/icon.png"></inf-avatar>
      <inf-avatar
        initials="XP"
        label="Xenia"></inf-avatar>
    </div>
    <div class="flex gap-2 m-2">
      <inf-avatar
        size="small"
        src="../../assets/images/icons/icon.png"></inf-avatar>
      <inf-avatar
        initials="XP"
        size="small"></inf-avatar>
      <inf-avatar
        size="small"
        label="Xenia"
        src="../../assets/images/icons/icon.png"></inf-avatar>
      <inf-avatar
        initials="XP"
        size="small"
        label="Xenia"></inf-avatar>
    </div>
  </div>
  <h1>Button</h1>
  <div class="card">
    <div class="flex gap-2 m-2">
      <button
        class="p-button-sm"
        icon="pi pi-check"
        iconPos="right"
        label="Primary"
        pButton></button>
      <button
        class="p-button-sm p-button-raised"
        icon="pi pi-check"
        iconPos="right"
        label="Primary"
        pButton></button>
      <button
        class="p-button-sm"
        label="Primary"
        pButton></button>
      <button
        class="p-button-sm"
        icon="pi pi-check"
        iconPos="left"
        label="Primary"
        pButton></button>
      <button
        class="p-button-sm p-button-outlined"
        label="Primary"
        pButton></button>
    </div>
    <div class="flex gap-2 m-2">
      <button
        icon="pi pi-check"
        iconPos="right"
        label="Primary"
        pButton></button>
      <button
        class="p-button-raised"
        icon="pi pi-check"
        iconPos="right"
        label="Primary"
        pButton></button>
      <button
        label="Primary"
        pButton></button>
      <button
        icon="pi pi-check"
        iconPos="left"
        label="Primary"
        pButton></button>
      <button
        class="p-button-outlined"
        label="Primary"
        pButton></button>
    </div>
    <div class="flex gap-2 m-2">
      <button
        class="p-button-lg"
        icon="pi pi-check"
        iconPos="right"
        label="Primary"
        pButton></button>
      <button
        class="p-button-lg p-button-raised"
        icon="pi pi-check"
        iconPos="right"
        label="Primary"
        pButton></button>
      <button
        class="p-button-lg"
        label="Primary"
        pButton></button>
      <button
        class="p-button-lg"
        icon="pi pi-check"
        iconPos="left"
        label="Primary"
        pButton></button>
      <button
        class="p-button-lg p-button-outlined"
        label="Primary"
        pButton></button>
    </div>
    <div class="flex gap-2 m-2">
      <button
        class="p-button-rounded p-button-sm"
        icon="pi pi-check"
        pButton></button>
      <button
        class="p-button-rounded p-button-sm p-button-raised"
        icon="pi pi-check"
        pButton></button>
      <button
        class="p-button-rounded p-button-sm p-button-outlined"
        icon="pi pi-check"
        pButton></button>
      <button
        class="p-button-rounded p-button-sm p-button-text"
        icon="pi pi-check"
        pButton></button>
    </div>
    <div class="flex gap-2 m-2">
      <button
        class="p-button-rounded"
        icon="pi pi-check"
        pButton></button>
      <button
        class="p-button-rounded p-button-raised"
        icon="pi pi-check"
        pButton></button>
      <button
        class="p-button-rounded p-button-outlined"
        icon="pi pi-check"
        pButton></button>
      <button
        class="p-button-rounded p-button-text"
        icon="pi pi-check"
        pButton></button>
    </div>
    <div class="flex gap-2 m-2">
      <button
        class="p-button-rounded p-button-lg"
        icon="pi pi-check"
        pButton></button>
      <button
        class="p-button-rounded p-button-lg p-button-raised"
        icon="pi pi-check"
        pButton></button>
      <button
        class="p-button-rounded p-button-lg p-button-outlined"
        icon="pi pi-check"
        pButton></button>
      <button
        class="p-button-rounded p-button-lg p-button-text"
        icon="pi pi-check"
        pButton></button>
    </div>

    <div class="flex gap-2 m-2">
      <button
        class="p-button-sm p-button-secondary"
        icon="pi pi-check"
        iconPos="right"
        label="Primary"
        pButton></button>
      <button
        class="p-button-sm p-button-raised p-button-secondary"
        icon="pi pi-check"
        iconPos="right"
        label="Primary"
        pButton></button>
      <button
        class="p-button-sm p-button-secondary"
        label="Primary"
        pButton></button>
      <button
        class="p-button-sm p-button-secondary"
        icon="pi pi-check"
        iconPos="left"
        label="Primary"
        pButton></button>
      <button
        class="p-button-sm p-button-outlined p-button-secondary"
        label="Primary"
        pButton></button>
    </div>
    <div class="flex gap-2 m-2">
      <button
        class="p-button-secondary"
        icon="pi pi-check"
        iconPos="right"
        label="Primary"
        pButton></button>
      <button
        class="p-button-raised p-button-secondary"
        icon="pi pi-check"
        iconPos="right"
        label="Primary"
        pButton></button>
      <button
        class="p-button-secondary"
        label="Primary"
        pButton></button>
      <button
        class="p-button-secondary"
        icon="pi pi-check"
        iconPos="left"
        label="Primary"
        pButton></button>
      <button
        class="p-button-outlined p-button-secondary"
        label="Primary"
        pButton></button>
    </div>
    <div class="flex gap-2 m-2">
      <button
        class="p-button-lg p-button-secondary"
        icon="pi pi-check"
        iconPos="right"
        label="Primary"
        pButton></button>
      <button
        class="p-button-lg p-button-raised p-button-secondary"
        icon="pi pi-check"
        iconPos="right"
        label="Primary"
        pButton></button>
      <button
        class="p-button-lg p-button-secondary"
        label="Primary"
        pButton></button>
      <button
        class="p-button-lg p-button-secondary"
        icon="pi pi-check"
        iconPos="left"
        label="Primary"
        pButton></button>
      <button
        class="p-button-lg p-button-outlined p-button-secondary"
        label="Primary"
        pButton></button>
    </div>
    <div class="flex gap-2 m-2">
      <button
        class="p-button-rounded p-button-sm p-button-secondary"
        icon="pi pi-check"
        pButton></button>
      <button
        class="p-button-rounded p-button-sm p-button-raised p-button-secondary"
        icon="pi pi-check"
        pButton></button>
      <button
        class="p-button-rounded p-button-sm p-button-outlined p-button-secondary"
        icon="pi pi-check"
        pButton></button>
      <button
        class="p-button-rounded p-button-sm p-button-text p-button-secondary"
        icon="pi pi-check"
        pButton></button>
    </div>
    <div class="flex gap-2 m-2">
      <button
        class="p-button-rounded p-button-secondary"
        icon="pi pi-check"
        pButton></button>
      <button
        class="p-button-rounded p-button-raised p-button-secondary"
        icon="pi pi-check"
        pButton></button>
      <button
        class="p-button-rounded p-button-outlined p-button-secondary"
        icon="pi pi-check"
        pButton></button>
      <button
        class="p-button-rounded p-button-text p-button-secondary"
        icon="pi pi-check"
        pButton></button>
    </div>
    <div class="flex gap-2 m-2">
      <button
        class="p-button-rounded p-button-lg p-button-secondary"
        icon="pi pi-check"
        pButton></button>
      <button
        class="p-button-rounded p-button-lg p-button-raised p-button-secondary"
        icon="pi pi-check"
        pButton></button>
      <button
        class="p-button-rounded p-button-lg p-button-outlined p-button-secondary"
        icon="pi pi-check"
        pButton></button>
      <button
        class="p-button-rounded p-button-lg p-button-text p-button-secondary"
        icon="pi pi-check"
        pButton></button>
    </div>

    <div class="flex gap-2 m-2">
      <button
        class="p-button-sm p-button-accent"
        icon="pi pi-check"
        iconPos="right"
        label="Primary"
        pButton></button>
      <button
        class="p-button-sm p-button-raised p-button-accent"
        icon="pi pi-check"
        iconPos="right"
        label="Primary"
        pButton></button>
      <button
        class="p-button-sm p-button-accent"
        label="Primary"
        pButton></button>
      <button
        class="p-button-sm p-button-accent"
        icon="pi pi-check"
        iconPos="left"
        label="Primary"
        pButton></button>
      <button
        class="p-button-sm p-button-outlined p-button-accent"
        label="Primary"
        pButton></button>
    </div>
    <div class="flex gap-2 m-2">
      <button
        class="p-button-accent"
        icon="pi pi-check"
        iconPos="right"
        label="Primary"
        pButton></button>
      <button
        class="p-button-raised p-button-accent"
        icon="pi pi-check"
        iconPos="right"
        label="Primary"
        pButton></button>
      <button
        class="p-button-accent"
        label="Primary"
        pButton></button>
      <button
        class="p-button-accent"
        icon="pi pi-check"
        iconPos="left"
        label="Primary"
        pButton></button>
      <button
        class="p-button-outlined p-button-accent"
        label="Primary"
        pButton></button>
    </div>
    <div class="flex gap-2 m-2">
      <button
        class="p-button-lg p-button-accent"
        icon="pi pi-check"
        iconPos="right"
        label="Primary"
        pButton></button>
      <button
        class="p-button-lg p-button-raised p-button-accent"
        icon="pi pi-check"
        iconPos="right"
        label="Primary"
        pButton></button>
      <button
        class="p-button-lg p-button-accent"
        label="Primary"
        pButton></button>
      <button
        class="p-button-lg p-button-accent"
        icon="pi pi-check"
        iconPos="left"
        label="Primary"
        pButton></button>
      <button
        class="p-button-lg p-button-outlined p-button-accent"
        label="Primary"
        pButton></button>
    </div>
    <div class="flex gap-2 m-2">
      <button
        class="p-button-rounded p-button-sm p-button-accent"
        icon="pi pi-check"
        pButton></button>
      <button
        class="p-button-rounded p-button-sm p-button-raised p-button-accent"
        icon="pi pi-check"
        pButton></button>
      <button
        class="p-button-rounded p-button-sm p-button-outlined p-button-accent"
        icon="pi pi-check"
        pButton></button>
      <button
        class="p-button-rounded p-button-sm p-button-text p-button-accent"
        icon="pi pi-check"
        pButton></button>
    </div>
    <div class="flex gap-2 m-2">
      <button
        class="p-button-rounded p-button-accent"
        icon="pi pi-check"
        pButton></button>
      <button
        class="p-button-rounded p-button-raised p-button-accent"
        icon="pi pi-check"
        pButton></button>
      <button
        class="p-button-rounded p-button-outlined p-button-accent"
        icon="pi pi-check"
        pButton></button>
      <button
        class="p-button-rounded p-button-text p-button-accent"
        icon="pi pi-check"
        pButton></button>
    </div>
    <div class="flex gap-2 m-2">
      <button
        class="p-button-rounded p-button-lg p-button-accent"
        icon="pi pi-check"
        pButton></button>
      <button
        class="p-button-rounded p-button-lg p-button-raised p-button-accent"
        icon="pi pi-check"
        pButton></button>
      <button
        class="p-button-rounded p-button-lg p-button-outlined p-button-accent"
        icon="pi pi-check"
        pButton></button>
      <button
        class="p-button-rounded p-button-lg p-button-text p-button-accent"
        icon="pi pi-check"
        pButton></button>
    </div>
  </div>
  <h1>Card</h1>
  <div class="card">
    <h2 class="font-bold">Title</h2>
    <div class="mb-1 text-secondary text-xs">
      This is the subtitle, contains the description
    </div>
    <p-divider></p-divider>
  </div>
  <h1>Connection</h1>
  <div class="card">
    <inf-connection [connection]="connection"></inf-connection>
  </div>
  <h1>Cookies</h1>
  <div class="card">
    <button
      (click)="openCookiesModal()"
      label="Open Cookies Modal"
      pButton></button>
    <inf-modal
      cancelLabel="Review Terms"
      confirmLabel="Accept"
      header="🍪 Cookie Settings"
      [(isModalOpen)]="isCookiesModalOpen">
      <div class="text-xs">
        Your use of this website is subject to an acceptable use policy, and to
        provide a better experience cookies and other mechanisms will store data
        subject to a cookies policy and privacy policy. <br /><br />By
        continuing, you agree to these terms.
      </div>
    </inf-modal>
  </div>
  <h1>Divider</h1>
  <div class="card test">
    <p-divider></p-divider>
  </div>
  <h1>Dropdown</h1>
  <div class="card test">
    <div class="flex gap-3">
      <p-dropdown
        [(ngModel)]="dropdownValue"
        [options]="dropdownOptions"
        optionLabel="name"
        placeholder="Select a City"></p-dropdown>
      <p-dropdown
        [(ngModel)]="dropdownValue2"
        [options]="dropdownOptions"
        optionLabel="name"
        placeholder="Select a City"></p-dropdown>
    </div>
  </div>
  <h1>Form Field</h1>
  <div class="card flex flex-column gap-3">
    <div class="field flex-1">
      <label class="align-items-center flex mb-0">
        <span>Label</span>
        <i class="ml-2 pi pi-question-circle"></i>
      </label>
      <div class="mb-2 text-xs">*extra info about this input field</div>
      <input
        type="text"
        [(ngModel)]="inputValue"
        pInputText
        placeholder="test" />
    </div>
    <div class="field flex-1">
      <div class="flex justify-content-between mb-2">
        <label class="align-items-center flex mb-0">
          <span>Label</span>
          <i class="ml-2 pi pi-question-circle"></i>
        </label>
        <div class="font-medium text-button text-sm">Create New</div>
      </div>
      <input
        type="text"
        [(ngModel)]="inputValue"
        pInputText
        placeholder="test" />
    </div>
    <div class="field flex-1">
      <div class="flex justify-content-between mb-2">
        <label class="align-items-center flex mb-0">
          <span>Label</span>
          <i
            class="ml-2 pi pi-question-circle"
            pTooltip="Please fill in this field"
            tooltipPosition="right"></i>
        </label>
        <p-inputSwitch
          pTooltip="Parametric?"
          tooltipPosition="top"></p-inputSwitch>
      </div>
      <input
        type="text"
        [(ngModel)]="inputValue"
        pInputText
        placeholder="test" />
    </div>
    <div class="field fill-v">
      <label class="align-items-center flex mb-0">
        <span>Label</span>
        <i class="ml-2 pi pi-question-circle"></i>
      </label>
      <div class="mb-2 text-xs">*extra info about this input field</div>
      <input
        type="text"
        [(ngModel)]="inputValue"
        pInputText
        placeholder="test" />
      <div class="align-self-end font-medium mt-1 text-button text-sm">
        Create New
      </div>
    </div>
    <div class="field fill-v">
      <label class="align-items-center flex mb-0">
        <span>Vector Input</span>
        <i class="ml-2 pi pi-question-circle"></i>
      </label>
      <div class="mb-2 text-xs">*extra info about this input field</div>
      <input
        type="text"
        [(ngModel)]="inputValue"
        [infKeyFilter]="vector3Regex"
        (hintChange)="vectorHint = $event"
        pInputText
        placeholder="0,0,0" />
      <div class="flex justify-content-between mt-1">
        <div class="text-xs">{{ vectorHint ? 'Hint: ' + vectorHint : '' }}</div>
        <div class="font-medium text-button text-sm">Create New</div>
      </div>
    </div>
    <div class="field fill-v">
      <label class="mb-0">Label</label>
      <div class="mb-2 text-xs">*extra info about this input field</div>
      <p-dropdown
        [(ngModel)]="dropdownValue"
        [options]="dropdownOptions"
        optionLabel="name"
        placeholder="Select a City"
        [editable]="true"></p-dropdown>
    </div>
  </div>
  <h1>Header</h1>
  <div class="card">
    <div class="flex flex-column gap-2">
      <inf-header></inf-header>
      <inf-header [includeNotifications]="true"></inf-header>
    </div>
  </div>
  <h1>Horizontal Menu Item</h1>
  <div class="card">
    <div class="flex gap-2">
      <inf-horizontal-menu-item
        *ngFor="let item of horizontalItems"
        [item]="item"></inf-horizontal-menu-item>
    </div>
  </div>
  <h1>Input</h1>
  <div class="card">
    <div class="flex flex-column gap-2">
      <input
        type="text"
        [(ngModel)]="inputValue"
        pInputText
        placeholder="test" />
    </div>
  </div>
  <h1>Icon</h1>
  <div class="card">
    <div class="flex gap-2">
      <inf-icon icon="pi pi-home"></inf-icon>
      <inf-icon
        icon="pi pi-home"
        type="secondary"></inf-icon>
      <inf-icon
        icon="pi pi-home"
        type="accent"></inf-icon>
    </div>
  </div>
  <h1>Integration Box</h1>
  <div class="card">
    <div class="flex gap-2">
      <inf-integration-box
        name="Cyclr"
        src="../../assets/images/svg/cyclr.svg"></inf-integration-box>
      <inf-integration-box
        [isConnected]="true"
        name="Cyclr"
        src="../../assets/images/svg/cyclr.svg"></inf-integration-box>
    </div>
  </div>
  <h1>Menu Item</h1>
  <div class="card">
    <inf-menu-item [item]="item"></inf-menu-item>
  </div>
  <h1>Modal</h1>
  <div class="card">
    <button
      (click)="openModal()"
      label="Open Modal"
      pButton></button>
    <inf-modal
      confirmLabel="Contact Support"
      header="Delete Account"
      [(isModalOpen)]="isModalOpen">
      <div class="text-sm">
        If you wish to delete
        <span class="font-bold text-sm">your account</span>, please get in touch
        with support.
      </div>
    </inf-modal>
  </div>
  <h1>Parameter</h1>
  <div class="card">
    <div class="flex flex-column">
      <div class="field">
        <label class="font-medium mb-3">1 - Colour</label>
        <div class="flex gap-2 ml-3">
          <div class="align-items-center flex flex-column px-1">
            <div
              class="mb-1 parameter-color"
              style="background-color: #d9d9d9"></div>
            <div class="text-xs">Grey</div>
          </div>
          <div class="align-items-center flex flex-column px-1">
            <div
              class="mb-1 parameter-color"
              style="background-color: #ffb333"></div>
            <div class="text-xs">Orange</div>
          </div>
          <div class="align-items-center flex flex-column px-1">
            <div
              class="mb-1 parameter-color"
              style="background-color: #ffcd29"></div>
            <div class="text-xs">Yellow</div>
          </div>
          <div class="align-items-center flex flex-column px-1">
            <div
              class="mb-1 parameter-color"
              style="background-color: #9742ac"></div>
            <div class="text-xs">Purple</div>
          </div>
        </div>
      </div>

      <div class="field">
        <label class="font-medium mb-3">2 - Select button</label>
        <p-selectButton
          [(ngModel)]="selectButtonValue"
          [options]="selectButtonOptions"
          styleClass="ml-3"></p-selectButton>
      </div>

      <div class="field w-4">
        <label class="font-medium mb-3">3 - Slider Range parameter</label>
        <div class="ml-3">
          <p-slider
            [(ngModel)]="slider2"
            [range]="true"></p-slider>
          <div class="flex gap-3 mt-3">
            <input
              [(ngModel)]="inputValue"
              pInputText
              placeholder="upper range"
              type="text" />
            <input
              [(ngModel)]="inputValue"
              pInputText
              placeholder="lower range"
              type="text" />
          </div>
        </div>
      </div>

      <div class="field w-4">
        <label class="font-medium mb-3">3 - Slider parameter</label>
        <div class="ml-3">
          <p-slider [(ngModel)]="slider1"></p-slider>
          <div class="flex gap-3 mt-3">
            <input
              class="w-6"
              [(ngModel)]="inputValue"
              pInputText
              placeholder="value"
              type="text" />
            <div class="w-6"></div>
          </div>
        </div>
      </div>

      <div class="field w-4">
        <label class="font-medium mb-3">4 - Switch</label>
        <div class="flex flex-column gap-2 ml-3">
          <div class="align-items-center flex gap-2">
            <p-inputSwitch class="flex"></p-inputSwitch>
            <label class="text-sm">Feature to activate</label>
          </div>
          <div class="align-items-center flex gap-2">
            <p-inputSwitch class="flex"></p-inputSwitch>
            <label class="text-sm">Feature to activate</label>
          </div>
          <div class="align-items-center flex gap-2">
            <p-inputSwitch class="flex"></p-inputSwitch>
            <label class="text-sm">Feature to activate</label>
          </div>
        </div>
      </div>

      <div class="field w-4">
        <label class="font-medium mb-3">5 - Radio button</label>
        <div class="flex flex-column gap-2 ml-3">
          <div class="field-radiobutton">
            <p-radioButton
              name="feature"
              [(ngModel)]="radioValue"
              value="Feature1"></p-radioButton>
            <label>Feature to activate</label>
          </div>
          <div class="field-radiobutton">
            <p-radioButton
              name="feature"
              [(ngModel)]="radioValue"
              value="Feature2"></p-radioButton>
            <label>Feature to activate</label>
          </div>
          <div class="field-radiobutton">
            <p-radioButton
              name="feature"
              [(ngModel)]="radioValue"
              value="Feature3"></p-radioButton>
            <label>Feature to activate</label>
          </div>
        </div>
      </div>
    </div>
  </div>
  <h1>Pricing</h1>
  <div class="card">
    <div class="flex">
      <inf-pricing
        description="Nunc consequat interdum varius sit. Nunc sequat interdum varius sit."
        [features]="[
          'Dui faucibus in ornare',
          'Morbi tincidunt augue',
          'Duis ultricies lacus sed'
        ]"
        includes="includes whatever we want"
        name="Planname"
        pricing="$100 /month"></inf-pricing>
    </div>
  </div>
  <h1>Radio Button</h1>
  <div class="card">
    <div class="flex flex-column gap-2">
      <div class="field-radiobutton">
        <p-radioButton
          name="city"
          [(ngModel)]="radioValue"
          value="Chicago"></p-radioButton>
        <label>Chicago</label>
      </div>
      <div class="field-radiobutton">
        <p-radioButton
          name="city"
          [(ngModel)]="radioValue"
          value="New York"></p-radioButton>
        <label>New York</label>
      </div>
    </div>
  </div>
  <h1>Search System</h1>
  <div class="card">
    <inf-search-system></inf-search-system>
  </div>
  <h1>Select Button</h1>
  <div class="card">
    <p-selectButton
      [(ngModel)]="selectButtonValue"
      [options]="selectButtonOptions"></p-selectButton>
  </div>
  <h1>Sidenav</h1>
  <div class="card">
    <inf-sidenav [menu]="sidenav"></inf-sidenav>
  </div>
  <h1>Slider</h1>
  <div class="card">
    <div class="flex flex-column gap-5">
      <p-slider [(ngModel)]="slider1"></p-slider>
      <p-slider
        [(ngModel)]="slider2"
        [range]="true"></p-slider>
    </div>
  </div>
  <h1>Status Box</h1>
  <div class="card">
    <div class="flex gap-2 m-2">
      <inf-status-box
        [count]="24"
        icon="pi pi-check"
        info="152 Received"
        status="Incoming"></inf-status-box>
    </div>
  </div>
  <h1>Switch</h1>
  <div class="card">
    <div class="align-items-center flex gap-2">
      <p-inputSwitch class="flex"></p-inputSwitch>
      <label class="text-sm">Test</label>
    </div>
  </div>
  <h1>Table</h1>
  <div class="card">
    <p-table
      [globalFilterFields]="['name']"
      [value]="tableValues">
      <ng-template pTemplate="header">
        <tr>
          <th>
            <div class="align-items-center flex justify-content-between">
              <span>Header Title</span>
              <p-columnFilter
                display="menu"
                field="name"
                type="text"></p-columnFilter>
            </div>
          </th>
          <th>Header Title</th>
          <th>Header Title</th>
          <th>Header Title</th>
          <th class="actions text-center">Actions</th>
        </tr>
      </ng-template>
      <ng-template
        pTemplate="body"
        let-value>
        <tr>
          <td>
            <div class="align-items-center flex gap-2">
              <inf-avatar
                initials="XP"
                size="small"></inf-avatar>
              {{ value.name }}
            </div>
          </td>
          <td>{{ value.country }}</td>
          <td>
            <inf-tag
              [label]="value.type"
              [status]="value.status"></inf-tag>
          </td>
          <td>{{ value.configurations | currency }}</td>
          <td>
            <div class="flex justify-content-between px-2">
              <button
                class="p-button-rounded p-button-sm p-button-text"
                icon="pi pi-check"
                pButton></button>
              <button
                class="p-button-rounded p-button-sm"
                icon="pi pi-ellipsis-h"
                pButton></button>
              <button
                class="p-button-rounded p-button-sm"
                icon="pi pi-ellipsis-v"
                pButton></button>
            </div>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
  <h1>Tag</h1>
  <div class="card flex flex-column gap-2">
    <div class="flex gap-2 m-2">
      <inf-tag
        label="status 1"
        status="danger"></inf-tag>
      <inf-tag
        label="status 1"
        [isSmall]="true"
        status="danger"></inf-tag>
    </div>
    <div class="flex gap-2 m-2">
      <inf-tag
        label="status 1"
        status="warning"></inf-tag>
      <inf-tag
        label="status 1"
        [isSmall]="true"
        status="warning"></inf-tag>
    </div>
    <div class="flex gap-2 m-2">
      <inf-tag
        label="status 1"
        status="success"></inf-tag>
      <inf-tag
        label="status 1"
        [isSmall]="true"
        status="success"></inf-tag>
    </div>
    <div class="flex gap-2 m-2">
      <inf-tag
        label="status 1"
        status="info"></inf-tag>
      <inf-tag
        label="status 1"
        [isSmall]="true"
        status="info"></inf-tag>
    </div>
  </div>
  <h1>Toast</h1>
  
  <div class="card flex flex-column gap-2">
    <!-- <button type="button" pButton pRipple (click)="showinfmsg()" label="Show Success"></button> -->
    <!-- <inf-info-message [title]="'Message Title'" [message]="'To start work with Infinitive, add your first product in Products page'" [actionBtn]="false"></inf-info-message> -->
    <p-toast></p-toast>
    <div class="flex gap-2">
      <button
        type="button"
        pButton
        pRipple
        (click)="showToast('success', 'thisisthedetail thisisthedetai thisisthedetai thisisthedetai thisisthedetai thisisthedetai thisisthedetai thisisthedetai thisisthedetai thisisthedetai thisisthedetai thisisthedetai thisisthedetai thisisthedetai thisisthedetai thisisthedetail thisisthedetail thisisthedetail ')"
        label="Success"
        class="p-button-success"></button>
      <button
        type="button"
        pButton
        pRipple
        (click)="showToast('info', 'this is the detail text')"
        label="Info"
        class="p-button-info"></button>
      <button
        type="button"
        pButton
        pRipple
        (click)="showToast('warn', 'this is the detail text')"
        label="Warn"
        class="p-button-warning"></button>
      <button
        type="button"
        pButton
        pRipple
        (click)="showToast('error', 'this is the detail text')"
        label="Error"
        class="p-button-danger"></button>
    </div>
  </div>
  <h1>User Menu</h1>
  <div class="card">
    <inf-user-menu></inf-user-menu>
  </div>
  <h1>Alias / WorkSpace Name</h1>
  <button
    (click)="openModalWS()"
    label="Open Modal"
    pButton></button>
  <inf-modal
    confirmLabel="Next"
    cancelLabel=""
    header="Your WorkSpace"
    [(isModalOpen)]="isModalOpenWS"
    (confirmActionChange)="assingName(name)">
    <div class="align-items-center field mt-3">
      <label class="block mb-2">Let's give it a name:</label>
      <input
        class="flex-1"
        [(ngModel)]="name"
        pInputText
        placeholder="Company name, Team name, ..."
        type="text" />
    </div>
  </inf-modal>
  <h1>Team Members</h1>
  <button
    (click)="openModalTM()"
    label="Open Modal"
    pButton></button>
  <inf-modal
    confirmLabel="Done"
    cancelLabel="Skip"
    header="Invite Team Members "
    [(isModalOpen)]="isModalOpenTM"
    (confirmActionChange)="inviteTeam()">
    <div
      class="align-items-center field mt-3"
      [formGroup]="inviteForm">
      <!-- <label class="block mb-2"></label> -->
      <input
        class="mb-3"
        formControlName="email1"
        pInputText
        pKeyFilter="email"
        placeholder="teamMember1@email.com"
        type="text" />
      <input
        class="mb-3"
        formControlName="email2"
        pInputText
        pKeyFilter="email"
        placeholder="teamMember2@email.com"
        type="text" />
      <input
        formControlName="email3"
        pInputText
        pKeyFilter="email"
        placeholder="teamMember3@email.com"
        type="text" />
    </div>
  </inf-modal>
</div>
