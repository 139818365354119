import { Component, OnInit } from '@angular/core';
import { VersionedOrder } from '../../../../generated-src';
import { CommonModule } from '@angular/common';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'inf-order',
  templateUrl: './order.component.html',
  standalone: true,
  imports: [
    CommonModule
  ]
})
export class OrderComponent implements OnInit {
  vo: VersionedOrder;

  constructor(private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.vo = this.route.snapshot.data['order'];
  }
}
