<div class="flex flex-column layout">
  <inf-sidenav
    *ngIf="isSidenavEnabled"
    [menu]="sidenav"></inf-sidenav>
  <div class="fill-v">
    <inf-header [includeNotifications]="true">
      <inf-history></inf-history>
    </inf-header>
    <div
      class="flex flex-1 mt-6"
      [class.layout-content]="isSidenavEnabled">
      <router-outlet class="router-outlet"></router-outlet>
    </div>
  </div>
</div>
