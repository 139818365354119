<div class="surface-section px-4 py-8 md:px-6 lg:px-8 text-center">
  <div class="mb-3 sm:flex-column">
    <p class="text-primary heading-xlarge text-semibold">One Product, </p>
    <p class="text-blue heading-xlarge text-semibold">Many Benefits</p>
  </div>
  <div class="landing-text-secondary body-medium text-regular mb-6">
    Solving the scalability challenge faced by build-to-order manufacturers
  </div>
  <div class="grid grid-nogutter">
    <div class="col-12 md:col-4 mb-4 px-5">
      <span class="p-3 shadow-2 mb-3 inline-block surface-card" style="border-radius: 10px">
        <i class="pi pi-desktop text-4xl text-blue-500"></i>
      </span>
      <div class="text-primary body-large-semibold mb-3 ">
        Offer Real Choice to Your Customers
      </div>
      <span class="landing-text-secondary body-small">
        Generate more sales by making choice accessible and informative. Guide your customers to choose the right
        product for them, while always able to visualise it and see price and delivery information.
      </span>
    </div>
    <div class="col-12 md:col-4 mb-4 px-5">
      <span class="p-3 shadow-2 mb-3 inline-block surface-card" style="border-radius: 10px">
        <i class="pi pi-table text-4xl text-blue-500"></i>
      </span>
      <div class="text-primary body-large-semibold mb-3 ">
        Integrate to Collaborate
      </div>
      <span class="landing-text-secondary body-small">
        Synchronise product and sales information seamlessly with diverse
        platforms including ERP, CRM, and E-Commerce, eliminating data silos and
        inconsistencies.
      </span>
    </div>
    <div class="col-12 md:col-4 mb-4 px-5">
      <span class="p-3 shadow-2 mb-3 inline-block surface-card" style="border-radius: 10px">
        <i class="pi pi-check-circle text-4xl text-blue-500"></i>
      </span>
      <div class="text-primary body-large-semibold mb-3">Reduce Cost of Sales</div>
      <span class="landing-text-secondary body-small">
        Let Infinitive generate the CAD and manufacturing data for each sale and focus engineer and designers time where
        it is needed.
      </span>
    </div>
    <div class="col-12 md:col-4 mb-4 px-5">
      <span class="p-3 shadow-2 mb-3 inline-block surface-card" style="border-radius: 10px">
        <i class="pi pi-globe text-4xl text-blue-500"></i>
      </span>
      <div class="text-primary body-large-semibold mb-3">Expand Your Market Reach</div>
      <span class="landing-text-secondary body-small">
        Selling customised or modular products via e-commerce breaks the barriers of growth and opens the door to any
        market you can deliver to.
      </span>
    </div>
    <div class="col-12 md:col-4 mb-4 px-5">
      <span class="p-3 shadow-2 mb-3 inline-block surface-card" style="border-radius: 10px">
        <i class="pi pi-file-edit text-4xl text-blue-500"></i>
      </span>
      <div class="text-primary body-large-semibold mb-3">
        Increase Customer Satisfaction
      </div>
      <span class="landing-text-secondary body-small">
        Give customers the confidence in their own choices and the freedom to explore options, and the ease of coming
        back for further purchases.
      </span>
    </div>
    <div class="col-12 md:col-4 md:mb-4 mb-0 px-3">
      <span class="p-3 shadow-2 mb-3 inline-block surface-card" style="border-radius: 10px">
        <i class="pi pi-share-alt text-4xl text-blue-500"></i>
      </span>
      <div class="text-primary body-large-semibold mb-3">
        Make 3D Part of Your Brand
      </div>
      <span class="landing-text-secondary body-small">
        Showcase your products dynamically with live configurators white-labelled and embedded in your website. Engage
        your audience by allowing them to visualize and customise configurations on any platform.
      </span>
    </div>
  </div>
</div>