import { Component, OnInit } from '@angular/core';
import { VersionGraph, VersionGraphVersion } from 'generated-src';
import { MermaidComponent, PatchworkService, TypesService } from '@harmanpa/ng-cae';
import { filter, map, mergeMap, Observable, of, switchMap, tap } from 'rxjs';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ButtonModule } from 'primeng/button';
import { formatDistanceToNow } from 'date-fns'
import { TooltipModule } from 'primeng/tooltip';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'inf-history',
  templateUrl: './history.component.html',
  standalone: true,
  imports: [
    CommonModule,
    MermaidComponent,
    OverlayPanelModule,
    ButtonModule,
    TooltipModule
  ],
})
export class HistoryComponent implements OnInit {
  type: string;
  id: string;
  currentVersion: string;

  spec?: string;
  undoVersion?: string;
  redoVersions: string[];

  constructor(private pwk: PatchworkService, private typesService: TypesService, private route: ActivatedRoute, private router: Router) {

  }

  ngOnInit(): void {
    this.install();
    // Follow changes in route
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(_ => this.install());
  }

  install(): void {
        // Fetch and merge all parameters from the route wherever this component is
        const getLastRoute: (route: ActivatedRoute) => ActivatedRoute = (route: ActivatedRoute) => route.firstChild
          ? getLastRoute(route.firstChild)
          : route;
        const snapshots = getLastRoute(this.route).snapshot.pathFromRoot;
        const data = snapshots.map(snapshot => snapshot.data).reduce((a,b) => Object.assign({}, a, b));
        const params = snapshots.map(snapshot => snapshot.params).reduce((a,b) => Object.assign({}, a, b));
        const queryParams = snapshots.map(snapshot => snapshot.queryParams).reduce((a,b) => Object.assign({}, a, b));
        this.type = data['type'];
        this.id = this.type ? params[this.type.toLowerCase() + '_id'] : undefined;
        this.currentVersion = queryParams['version'];
        this.makeSpec().subscribe(spec => this.spec = spec);
  }

  getHistory(): Observable<VersionGraph> {
    if (this.type && this.id) {
      return this.typesService.noun(this.type).pipe(
        // @ts-ignore
        mergeMap(noun => this.pwk.getAPI()['get' + noun + 'History'](this.id) as Observable<VersionGraph>)
      );
    }
    return of({});
  }

  undo(): void {
    if (this.undoVersion) {
      this.currentVersion = this.undoVersion;
      // this.currentVersionChange.emit(this.currentVersion);
    }
  }

  redo(): void {
    if (this.redoVersions && this.redoVersions.length > 0) {
      // TODO: Ask the user which one
      this.currentVersion = this.redoVersions[this.redoVersions.length-1];
      // this.currentVersionChange.emit(this.currentVersion);
    }
  }

  setUndoRedoVersions(vg: VersionGraph): void {
    if (this.currentVersion) {
      this.undoVersion = (vg.versions||[])
        .filter(v => v.version===this.currentVersion)
        .map(v => v.parents && v.parents.length>0 ? v.parents[0] : undefined)
        .find(_ => true);
      this.redoVersions = (vg.versions||[])
        .filter(v => v.parents && v.parents.includes(this.currentVersion))
        .map(v => v.version as string);
    }
  }

  makeSpec(): Observable<string> {
    return this.getHistory().pipe(
      tap(vg => this.setUndoRedoVersions(vg)),
      map(versionGraph => {
        console.log('vg', versionGraph);
        // Make a map of the child versions, we only track parents
        const children: { [id: string]: string[] } = {};
        (versionGraph.versions || []).forEach(v => {
          (v.parents || []).forEach(p => {
            if (children[p]) {
              children[p].push(v.version as string);
            } else {
              children[p] = [v.version as string];
            }
          });
        });
        console.log('children', children);
        // Now make a map of the branches
        const branches: { [id: string]: string } = {};
        // Set forks and the main branch
        (versionGraph.versions || []).forEach(v => {
          const kids = children[v.version as string] || [];
          if (kids.length > 1) {
            kids.slice(1).forEach(kid => branches[kid] = kid);
          }
          if ((v.parents || []).length === 0) {
            branches[v.version as string] = 'main';
          }
        });
        // Set the branch name for child commits
        Object.entries(branches).forEach(versionBranch => {
          const childVersions: string[] = [];
          let versionKids = children[versionBranch[0]] || [];
          while(versionKids.length>0) {
            childVersions.push(versionKids[0]);
            versionKids = children[versionKids[0]] || [];
          }
          childVersions.forEach(v => branches[v] = versionBranch[1]);
        });
        console.log('branches', branches);
        // Now iterate over the versions and make commits, add a branch if needed
        let newSpec = 'gitGraph TB:\n';
        let currentBranch = 'main';
        const startedBranches = ['main'];
        (versionGraph.versions || []).forEach(v => {
          const branch = branches[v.version as string];
          if (branch !== currentBranch) {
            if (!startedBranches.includes(branch)) {
              newSpec += '\tbranch ' + branch + '\n';
              startedBranches.push(branch);
            }
            newSpec += '\tcheckout ' + branch + '\n';
            currentBranch = branch;
          }
          newSpec += '\tcommit id: "' + this.describeVersion(v) + '"' + (this.currentVersion === v.version ? ' type: HIGHLIGHT' : '') + '\n';
        });
        return newSpec;
      })
    );
  }

  describeVersion(v: VersionGraphVersion): string {
    return (v.version as string) + (v.created
      ? ' ' + formatDistanceToNow(v.created as Date)
      : '');
  }

}
