<div class="fill-v">
  <div class="align-items-center flex justify-content-between">
    <h2 class="font-medium mb-2">Activity</h2>
<!--    <button-->
<!--      class="ml-auto p-button-sm p-button-outlined"-->
<!--      icon="pi pi-sync"-->
<!--      loadingIcon="pi pi-sync pi-spin"-->
<!--      iconPos="left"-->
<!--      label="Sync External"-->
<!--      pTooltip="Synchronise data from external integrations"-->
<!--      tooltipPosition="bottom"-->
<!--      pButton-->
<!--      [loading]="ordersSyncing"-->
<!--      (click)="syncOrders()"></button>-->
  </div>
  <div class="font-medium subtitle mb-6">WORKSPACE ACTIVITY FEED</div>

  <div class="card flex flex-column">
    <p-timeline [value]="events" align="alternate" styleClass="customized-timeline">
      <ng-template pTemplate="marker" let-event>
        <span
          class="flex w-2rem h-2rem align-items-center justify-content-center text-white border-circle z-1 shadow-1"
          [style]="{ 'background-color': event.color }">
                <i [class]="event.icon"></i>
        </span>
      </ng-template>
      <ng-template pTemplate="content" let-event>
        <p-card [header]="event.status" [subheader]="event.date">
          <img
            *ngIf="event.image"
            [ngSrc]="'https://primefaces.org/cdn/primeng/images/demo/product/' + event.image"
            [alt]="event.name"
            width="200"
            height="100"
            class="shadow-2 img-object-contain" />
          <p>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Inventore sed consequuntur error repudiandae numquam deserunt quisquam repellat libero asperiores earum nam nobis, culpa ratione quam perferendis esse, cupiditate
            neque quas!
          </p>
          <p-button label="Read more" [text]="true" />
        </p-card>
      </ng-template>
    </p-timeline>

    <inf-empty-state
      *ngIf="false"
      src="../../assets/images/empty-states/orders.svg"
      subtitle="No Activity"
      title="Activity will be shown here"></inf-empty-state>
  </div>
</div>
