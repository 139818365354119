/**
 * InfinitivePlatform API
 * Modular Configurator
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { GeometryNodeSettings } from './geometryNodeSettings';

/**
 * Settings for CAD geometry
 */
export interface GeometrySettings { 
    /**
     * Coordinate system of CAD model
     */
    cadOrientation?: GeometrySettings.CadOrientationEnum;
    /**
     * Create UV map for applying textures
     */
    uvMap?: boolean;
    /**
     * Convert geometry to be extrudable with attributes
     */
    autoExtrude?: boolean;
    /**
     * Maximum depth of assembly
     */
    collapseDepth?: number;
    /**
     * Path to root node in assembly
     */
    rootNode?: Array<string>;
    /**
     * Settings for child nodes
     */
    nodes?: { [key: string]: GeometryNodeSettings; };
}
export namespace GeometrySettings {
    export type CadOrientationEnum = 'PositiveZ' | 'NegativeZ' | 'PositiveY' | 'NegativeY';
    export const CadOrientationEnum = {
        PositiveZ: 'PositiveZ' as CadOrientationEnum,
        NegativeZ: 'NegativeZ' as CadOrientationEnum,
        PositiveY: 'PositiveY' as CadOrientationEnum,
        NegativeY: 'NegativeY' as CadOrientationEnum
    };
}
