/**
 * InfinitivePlatform API
 * Modular Configurator
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface ConfiguredPart { 
    documentId?: string;
    wvmType?: ConfiguredPart.WvmTypeEnum;
    wvmId?: string;
    elementId?: string;
    configuration?: string;
    transform?: Array<number>;
    instanceId?: string;
    partId?: string;
    name?: string;
    fixed?: boolean;
}
export namespace ConfiguredPart {
    export type WvmTypeEnum = 'Workspace' | 'Version' | 'Microversion';
    export const WvmTypeEnum = {
        Workspace: 'Workspace' as WvmTypeEnum,
        Version: 'Version' as WvmTypeEnum,
        Microversion: 'Microversion' as WvmTypeEnum
    };
}
