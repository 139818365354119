import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UserService } from '@harmanpa/ng-cae';
import { ButtonModule } from 'primeng/button';

@Component({
  selector: 'inf-temp-card',
  templateUrl: './temp-card.component.html',
  standalone: true,
  imports:[ButtonModule,CommonModule]
})

export class TempCardComponent {
  @Input() icon: string;
  @Input() type: 'embedded' | 'float' = 'embedded';
  @Input() key: string = 'tempCard';
  @Input() title: string;
  @Input() message:string;
  @Input() actionBtnLabel: string;
  @Input() actionBtn: boolean = false;
  @Input() closeBtn: boolean = false;
  @Output() CTAchange: EventEmitter<boolean> = new EventEmitter<boolean>();
  show: boolean = true;
  
  constructor(
    private userService: UserService
    ){}

  ngOnInit():void {

  }
  getType(): string {
    if (this.type === 'embedded') {
        return "embedded";
    } else if (this.type === 'float') {
        return "float";
    }else{
      return 'embedded'
    }
}
  
  emitAction():void {
    this.CTAchange.emit(true);
  }
  
  close():void {
    //TODO: this should set Data Item to user so it doesn't show again...
    this.show=false;
  }
}
