<p-overlayPanel styleClass="popup-batch-editor radius-minimal mb-1" (onHide)="onOverlayHide()" #categoryOp>  
  <!-- Categories in common -->
  <div class="common">
    <div *ngFor="let category of categoriesFiltered.common;" class="inline-flex relative">
      <inf-tag [isSmall]="true" [label]="category" class="inline-flex" classes="px-1" status="info"></inf-tag>
      <i class="pi pi-times right-0 absolute top-50" (click)="deleteCategory(category, selectedProducts)"></i>
    </div>
  </div>

  <input
    class="shadow-none border-noround border-none border-bottom-1 py-1 body-small"
    [(ngModel)]="categoryInput"
    (keyup)="searchCategory($event)"
    pInputText
    placeholder="Search or Create new"
    type="text" />

  <p *ngIf="categoryInput || categoriesSuggestions.length == 0" class="helper-text body-xtra-small font-italic my-2">Press Enter or comma to create a new category</p>
    
  <!-- Categories suggestions -->
  <div class="options">
    <ng-container *ngFor="let category of categoriesSuggestions;" class="flex align-items-center">
      <p-button
        *ngIf="!isRenamingCategory || extraOptionsCategory !== category"
        styleClass="p-button-text text-left w-full body-small border-none"
        [label]="category"
        (click)="addCategory(category)"
      >
        <p-button
          *ngIf="!isRenamingCategory"
          pRipple
          icon="pi pi-ellipsis-h"
          class="extra-options flex-order-1 flex"
          styleClass="p-button-text p-button-rounded body-small border-none h-1rem w-1rem"
          (click)="showExtraOptions($event, category)">
        </p-button>
      </p-button>  
      <input
        *ngIf="isRenamingCategory && extraOptionsCategory === category"
        #searchCategoryInput
        class="shadow-none border-noround border-none border-bottom-1 py-1 body-small surface-primary"
        pInputText
        [(ngModel)]="renamingCategoryStr"
        (keyup)="renameCategory($event)"
        placeholder="Category name"
        type="text" />
    </ng-container>
    <p-menu
      #extraOptionsMenu
      [popup]="true"
      appendTo="body"
      styleClass="toolbar-batch-editor-menu p-0"
      [model]="extraMenuOptions">
    </p-menu>
  </div>
</p-overlayPanel>
