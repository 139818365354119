import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DividerModule } from 'primeng/divider';
import { ButtonModule } from 'primeng/button';

@Component({
  selector: 'inf-modal',
  standalone: true,
  imports: [ButtonModule, CommonModule, DividerModule],
  templateUrl: './modal.component.html',
})
export class ModalComponent {
  @Input() cancelLabel: string = 'Cancel';
  @Input() confirmLabel: string = 'Confirm';
  @Input() header: string = '';
  @Input() isMaskCancelable: boolean = true;
  @Input() isModalOpen: boolean = false;

  @Output() cancelActionChange: EventEmitter<void> = new EventEmitter<void>();
  @Output() confirmActionChange: EventEmitter<void> = new EventEmitter<void>();
  @Output() isModalOpenChange: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  closeModal(): void {
    this.isModalOpen = false;
    this.isModalOpenChange.emit(this.isModalOpen);
  }

  maskClick(): void {
    if (this.isMaskCancelable) {
      this.closeModal();
    }
  }

  cancel(): void {
    this.cancelActionChange.emit();
    this.closeModal();
  }

  confirm(): void {
    this.confirmActionChange.emit();
    this.closeModal();
  }
}
