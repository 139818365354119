import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonModule } from 'primeng/button';
import { Connection } from '../../../../shared/model/connection.model';
import { TagComponent } from '../../../../shared/components/tag/tag.component';

@Component({
  selector: 'inf-connection',
  standalone: true,
  imports: [ButtonModule, CommonModule, TagComponent],
  templateUrl: './connection.component.html',
})
export class ConnectionComponent {
  @Input() connection: Connection = {};
  @Output() deleteChange: EventEmitter<void> = new EventEmitter<void>();
}
