
import { Component, Input, EventEmitter, Output, ViewChild, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonModule } from 'primeng/button';
import { EmptyStateComponent } from '../../shared/components/empty-state/empty-state.component';
import { RouterLink } from '@angular/router';
import { Table, TableModule } from 'primeng/table';
import { TagComponent } from '../../shared/components/tag/tag.component';
import { SearchSystemComponent } from '../../shared/components/search-system/search-system.component';
import { StatusPipe } from '../../shared/pipes/status.pipe';
import { DividerModule } from 'primeng/divider';
import { InputTextModule } from 'primeng/inputtext';
import { MenuModule } from 'primeng/menu';
import { AlertService, ConfirmService, DocumentSummary } from '@harmanpa/ng-cae';
import { catchError, finalize, map, mergeMap } from 'rxjs/operators';
import { from, of } from 'rxjs';
import { ContextMenuModule } from 'primeng/contextmenu';
import { ChipModule } from 'primeng/chip';
import { DefaultService, VersionedModule } from 'generated-src';
import { TruncatePipe } from '../../shared/pipes/truncate.pipe';
import { HttpErrorResponse } from '@angular/common/http';
import { ToolbarBatchEditorComponent } from 'src/app/shared/components/toolbar-batch-editor/toolbar-batch-editor.component';
import { ProgressComponent } from '../../shared/components/progress/progress.component';
import { AvatarComponent } from '../../shared/components/avatar/avatar.component';

@Component({
  selector: 'inf-products-overview',
  standalone: true,
  imports: [
    AvatarComponent,
    ButtonModule,
    ChipModule,
    CommonModule,
    ContextMenuModule,
    DividerModule,
    EmptyStateComponent,
    InputTextModule,
    MenuModule,
    ProgressComponent,
    RouterLink,
    SearchSystemComponent,
    StatusPipe,
    TableModule,
    TagComponent,
    TruncatePipe,
    ToolbarBatchEditorComponent
  ],

  templateUrl: './products-overview.component.html',
})
export class ProductsOverviewComponent implements OnInit {
  @Input() products: DocumentSummary[] = [];
  filteredProducts: DocumentSummary[] = [];
  @Input() isOverview: boolean = false;
  @Input() includeSearch: boolean = true;
  @Input() rowsNumber: number = 10;
  /** Boolean to know if its to show the batch actions or only the search bar */
  @Input() showBatchAction = true;
  @Output() selection = new EventEmitter<DocumentSummary[]>();
  first: number = 0;
  selectedProducts: DocumentSummary[] = [];
  totalCount: number;
  @ViewChild('dt1') table: Table;

  constructor(
    private api: DefaultService,
    private confirmService: ConfirmService,
    private alertService: AlertService
  ) {
  }

  ngOnInit():void  {
    this.totalCount = this.products.length;
    if (this.isOverview) {
      this.products = this.products.slice(0, this.rowsNumber);
    }
    this.filteredProducts = this.products;
  }

  /**
   * Delete product(s) (selected products or clicked row)
   * @param vm - the product where we clicked on delete (if undefined then it means its to delete selected products)
   */
  deleteProduct(vm?: DocumentSummary): void {
    //Get products
    let products: DocumentSummary[];
    if (vm) {
      products = [vm];
    } else {
      products = this.selectedProducts;
    }

    //Get the names of selected products combined (for messages)
    const name = products.map(product => product?.data?.['name']).join(', ');

    let deletionUnsuccessful: DocumentSummary[] = []; // List containing the names of the unsuccessful deletions
    this.confirmService.onConfirmed(
      from(products).pipe(  //On confirmed, loop through all products to delete them
        mergeMap((product:DocumentSummary) =>
          //Delete product
          this.api.deleteModule(product?.id as string, true, true).pipe(
            map((res: any) => {
              //Remove from list
              this.products = this.products.filter(
                _product => _product.id !== product?.id
              );
              return true;
            }),
            catchError((err: any) => {  //On deletion error
              if ((err instanceof HttpErrorResponse) && (err as HttpErrorResponse).status == 424) {
                // It was blocked because there are dependencies
                const dependencies: string[] = (err as HttpErrorResponse).headers.getAll('Patchwork-Dependency') || [];
                this.alertService.warning('Product cannot be deleted as it is used', 'Product Is Used');
              } else {
                this.alertService.error(err, 'Error Deleting Product');
              }

              deletionUnsuccessful.push(product);  //Add module name to errors
              return of(false);
            })
          )
        ),
        finalize(() => {  //After all deletions occur
          if (products.length - deletionUnsuccessful.length > 0) { //If there was any successfull deletion
            if (deletionUnsuccessful.length === 0) { //If there was no error
              this.alertService.success(
                products.length > 1 ?
                  'All selected products have been successfully deleted.':
                  'The product has been successfully deleted.',
                'Products deleted');
            } else {  //If there was an error in some modules to delete
              this.alertService.success(
                products.length - deletionUnsuccessful.length > 1 ?
                `Besides ${deletionUnsuccessful.map(product => product?.data?.['name']).join(', ')}, the other products were deleted` :
                `${products.filter(product => !deletionUnsuccessful.includes(product)).map(product => product?.data?.['name'])[0]} deleted successfully`
                , 'Products deleted'
              );
            }
            // Unselect selected products
            this.selectedProducts = [];
          }
        })
      ),
      `Are you sure you want to delete ${products.length === 1 ? 'this product' : 'these products'}?
      This action cannot be undone.`,
      "Delete product",
      'pi pi-exclamation-triangle',
      undefined,
      true,
      true,
      'Yes',
      'No',
      '',
      ''
    );
  }

  /**
   * Duplicates product
   */
  duplicateProduct(vm?: DocumentSummary): void {
    //Add new products
    this.api.addModule({name: this.getDuplicatedName(vm?.data?.["name"])}, vm?.id, vm?.head).subscribe(
      (vm:VersionedModule) => {
        //Update products list
        this.api.summariseModule(vm.id as string).subscribe((ds: DocumentSummary) => {
          this.products = [ds, ...this.products];
        });

        this.alertService.success(
          `Product duplicated Successfully`,
          `Product duplicated`
        );

        //Unselect selected products
        this.selectedProducts = [];
      }
    );
  }

  getDuplicatedName(name: string): string {
    name += " Duplicated"; // Add duplicated
    while (true) {
      if (this.products.find(product => product.data?.["name"] === name)) {
        name += " Duplicated";
      } else {
        return name;
      }
    }
  }


 /**
   * Opens edit of product(s) (selected products or clicked row)
   * @param vm - the product where we clicked on edit (if undefined then it means its to edit selected products)
   */
 edit(vm: DocumentSummary): void {
  // NB: Changed from having version else risk that we edit old versions
  // const url = `/products/${vm.id}?version=${vm.head}`;
  //Get products
  let products: DocumentSummary[];
  if (vm) {
    products = [vm];
  } else {
    products = this.selectedProducts;
  }

  //Contains the list of opened windows (to check for popup not accepted)
  const list: (Window | null)[] = [];
  //Open products
  for (const product of products) {
    const url = `/products/${product.id}`;
    list.push(window.open(url, '_blank'));
    const newWindow = list[list.length - 1];

    //If popup not opening
    if (!newWindow || newWindow.closed || typeof newWindow.closed == 'undefined') {
      const previousTab = list[list.length - 2] ?? window;

      //On firefox it needs to wait for onload
      if (navigator.userAgent.indexOf("Firefox") > 0) {
        previousTab.onload = function() {
          previousTab.alert("Couldn't open products because popups are not allowed. Please enable pop-ups and redirects for this webSite.");
          previousTab.close();
        };
      } else {
        previousTab.alert("Couldn't open products because popups are not allowed. Please enable pop-ups and redirects for this webSite.");
        previousTab.close();
      }
      break;
    }
  }

  //Unselect selected products
  this.selectedProducts = [];
}
  filterData(data: DocumentSummary[]): void {
    if (this.table) { //On filter change, go to first page
      this.table.first = 0;
    }
    this.filteredProducts = data;
    console.log(data);
  }

  onSelectionChange(): void  {
    this.selection.emit(this.selectedProducts);
  }

  categoryTrackBy(index: number, category: string): number {
    return index;
  }

  getOwnerName(email:string): string {
    return email.split('@')[0];
  }
}
