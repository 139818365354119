import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonModule } from 'primeng/button';
import { Router } from '@angular/router';
import { NgOptimizedImage } from '@angular/common';

@Component({
  selector: 'inf-not-found',
  standalone: true,
  imports: [ButtonModule, CommonModule,NgOptimizedImage],
  templateUrl: './not-found.component.html',
})
export class NotFoundComponent {

  constructor(private router: Router) { }

  redirect(): void {
    this.router.navigate(['/']);
  }
}
