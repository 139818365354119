/**
 * InfinitivePlatform API
 * Modular Configurator
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Whether connection can be rotated on axis
 */
export interface ConnectorOrientation { 
    /**
     * Freedom of the rotation
     */
    freedom?: ConnectorOrientation.FreedomEnum;
    /**
     * Angles of rotation allowed, if applicable
     */
    angles?: Array<number>;
}
export namespace ConnectorOrientation {
    export type FreedomEnum = 'Fixed' | 'Free' | 'Constrained';
    export const FreedomEnum = {
        Fixed: 'Fixed' as FreedomEnum,
        Free: 'Free' as FreedomEnum,
        Constrained: 'Constrained' as FreedomEnum
    };
}
