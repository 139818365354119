<div id="contact">
  <div class="surface-invert-dark px-4 py-5 md:px-6 lg:px-8">
    <div class="grid">
      <div class="col-12 lg:col-6">
        <div class="text-blue body-large mb-3">CONTACT US</div>
        <!-- <div class="text-900 body-small mb-4">Contact Us</div> -->
        <div class="text-primary heading-medium font-bold mb-2">Get in touch</div>
        <p class="landing-text-secondary line-height-3 pr-0 lg:pr-5">
          Ready to transform your business? Get in touch with us today to learn
          more.
        </p>
      </div>
      <div class="col-12 md:col-6 lg:col-3">
        <div class="flex align-items-start">
          <div>
            <span class="flex align-items-center justify-content-center surface-100 border-round mr-3"
              style="height: 3rem; width: 3rem">
              <i class="pi pi-map-marker text-900 text-4xl"></i>
            </span>
          </div>
          <div>
            <span class="body-large text-900 mb-2 font-medium">Address</span>
            <p class="landing-text-secondary line-height-2 body-small mb-1 mt-2">26 Hamilton Terrace</p>
            <p class="landing-text-secondary line-height-2 body-small mb-1 mt-2">
              Leamington Spa CV32 4LY
            </p>
            <p class="landing-text-secondary line-height-2 body-small  mt-2">United Kingdom</p>
          </div>
        </div>
      </div>
      <div class="col-12 md:col-6 lg:col-3">
        <div class="flex align-items-start">
          <div>
            <span class="flex align-items-center justify-content-center surface-100 border-round mr-3"
              style="height: 3rem; width: 3rem">
              <i class="pi pi-inbox text-900 text-4xl"></i>
            </span>
          </div>
          <div>
            <span class="body-large text-900 mb-2 font-medium">Email</span>
            <div>
              <a href="mailto:support@infinitive.io" class="landing-text-secondary line-height-3 body-small">support@infinitive.io</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- <img
  alt="Image"
  src="assets/images/misc/map1.png"
  class="w-full" /> -->
</div>