import { ConfirmService } from '@harmanpa/ng-cae';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { DividerModule } from 'primeng/divider';
import { ButtonModule } from 'primeng/button';
import { DefaultService, DocumentSummary, VersionedPublishedConfigurator } from 'generated-src';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { combineLatest, of } from 'rxjs';

import { ConfiguratorsListComponent } from './configurators-list/configurators-list.component';

@Component({
  selector: 'inf-configurators-overview',
  standalone: true,
  imports: [
    CommonModule,
    ButtonModule,
    RouterModule,
    ConfiguratorsListComponent,
    DividerModule
  ],
  templateUrl: './configurators-overview.component.html',
})
export class ConfiguratorsOverviewComponent {
  loading: boolean = false;

  totalCount: number;

  configurators: DocumentSummary[] = [];
  configuratorsWithPublished: [DocumentSummary, VersionedPublishedConfigurator | undefined][] = [];
  publishedConfigurators: VersionedPublishedConfigurator[] = [];

  constructor(
    private api: DefaultService,
    private confirmService: ConfirmService,
  ) {
  }

  ngOnInit():void  {
    this.getConfigurators();
  }

  getConfigurators(): void {
    // Fetch the existing set of configurators
    this.api
      .findProducts()
      .subscribe(configurators => {
        this.configurators = configurators;
        this.totalCount = configurators.length;
        this.api
          .findPublishedConfigurators()
          .pipe(
            mergeMap(pcs => pcs.length === 0
              ? of([])
              : combineLatest(
                pcs.map(pc =>
                  this.api.getPublishedConfigurator(
                    pc.id as string,
                    pc.head as string
                  )
                )
              )
            )
          )
          .subscribe(configurators => {
            this.publishedConfigurators = configurators;
            // Map to the configurators
            this.configuratorsWithPublished = this.configurators.map(configurator =>
              [configurator, this.publishedConfigurators.find(published => published.document?.product?.id === configurator.id)] as [DocumentSummary, VersionedPublishedConfigurator | undefined])
              .sort((a, b) => this.compare(a, b)).slice(0, 5);
          });
      });
  }

  compare(configuratorA: [DocumentSummary, VersionedPublishedConfigurator | undefined], configuratorB: [DocumentSummary, VersionedPublishedConfigurator | undefined]): number {
    if (!!configuratorA[1] && !!configuratorB[1]) {
      // Both published, compare published date
      return this.recentDate((configuratorA[1] as VersionedPublishedConfigurator).versionDate as Date, (configuratorB[1] as VersionedPublishedConfigurator).versionDate as Date);
    } else if (!configuratorA[1] && !configuratorB[1]) {
      // Neither published, compare last edited
      return this.recentDate(configuratorA[0].lastEdited as Date, configuratorB[0].lastEdited as Date);
    } else {
      // -1 if a is published and b not, +1 if vice-versa
      return !!configuratorA[1] ? -1 : +1;
    }
  }

  configTrackBy(index:number, config:DocumentSummary): string{
    return config.id?config.id:'';
  }

  recentDate(a: Date, b: Date): number {
    return a < b ? 1 : (b < a ? -1 : 0);
  }
}
