import {Injectable} from '@angular/core';
import { BaseMaterialFactory, RendererService, Part, PartMetaData, MaterialType, MaterialStyling } from '@harmanpa/ng-cae';
import { Observable } from 'rxjs';
import { Color, Material } from 'three';

@Injectable()
export class ViewerMaterialFactory extends BaseMaterialFactory<Part> {
    private borderStyling: { type: "basic", lightness: number } = {
        type: "basic",
        lightness: 0.35
    }

    constructor(private rendererService: RendererService) {
        super();
    }

    createMaterialColored(part: Part & {
        name?: string;
    }, color?: Color, opacity?: number, meta?: PartMetaData, type?: MaterialType, materialStyling?: MaterialStyling, materialSent?: Material): Material | Observable<Material> {
        //Make borders lighter
        if (materialStyling && !materialStyling.border) {
            (materialStyling as MaterialStyling).border = this.borderStyling;
        }

        const m = super.createMaterialColored(part, color, opacity, meta, type, materialStyling, materialSent) as Material;
        m.vertexColors = true;
        console.log('created material', m);
        return m;
    }
    // createMaterialColored(part: PartComponent,
    //                       color: THREE.Color, opacity: number,
    //                       meta: PartMetaData): THREE.Material | Observable<THREE.Material> {
    //     console.log('Requesting material for ' + JSON.stringify(color));
    //     const temp = new THREE.Color('rgb(255, 255, 255)').convertLinearToSRGB();
    //     // return new THREE.MeshToonMaterial({color: temp, opacity: 0.5});
    //     // return new THREE.MeshBasicMaterial({
    //     //     color: temp,
    //     //     // shadowSide: THREE.FrontSide
    //     //     // specular: 0x111111, shininess: 200, opacity: appearance.opacity
    //     // });
    //     return new THREE.MeshPhongMaterial({color: temp, opacity: 0.5});
    // }
}
