/**
 * InfinitivePlatform API
 * Modular Configurator
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { QuantityDefinition } from './quantityDefinition';

/**
 * Parameters available for this module
 */
export interface ParameterDefinition { 
    /**
     * Name of parameter
     */
    name?: string;
    /**
     * Description of parameter
     */
    description?: string;
    /**
     * Type of parameter
     */
    parameterType?: ParameterDefinition.ParameterTypeEnum;
    quantity?: QuantityDefinition;
    /**
     * Effect of parameter
     */
    effect?: ParameterDefinition.EffectEnum;
    /**
     * Default value
     */
    defaultValue?: string;
    /**
     * All values, if an enumeration
     */
    enumValues?: Array<string>;
}
export namespace ParameterDefinition {
    export type ParameterTypeEnum = 'Boolean' | 'String' | 'Enumeration' | 'Quantity';
    export const ParameterTypeEnum = {
        Boolean: 'Boolean' as ParameterTypeEnum,
        String: 'String' as ParameterTypeEnum,
        Enumeration: 'Enumeration' as ParameterTypeEnum,
        Quantity: 'Quantity' as ParameterTypeEnum
    };
    export type EffectEnum = 'Design' | 'Display';
    export const EffectEnum = {
        Design: 'Design' as EffectEnum,
        Display: 'Display' as EffectEnum
    };
}
