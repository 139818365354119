import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AvatarComponent } from '../shared/components/avatar/avatar.component';
import { ButtonModule } from 'primeng/button';
import { DividerModule } from 'primeng/divider';
import { MenuItem, MessageService } from 'primeng/api';
import { MenuItemComponent } from '../shared/components/menu-item/menu-item.component';
import { InputTextModule } from 'primeng/inputtext';
import { TagComponent } from '../shared/components/tag/tag.component';
import { StatusBoxComponent } from '../shared/components/status-box/status-box.component';
import { HeaderComponent } from '../layout/header/header.component';
import { IntegrationBoxComponent } from '../shared/components/integration-box/integration-box.component';
import { SidenavComponent } from '../layout/sidenav/sidenav.component';
import { MenuGroup } from '../shared/model/menu-group.model';
import { PricingComponent } from '../shared/components/pricing/pricing.component';
import { UserMenuComponent } from '../layout/header/user-menu/user-menu.component';
import { TableModule } from 'primeng/table';
import { MenuVariants } from '../shared/helpers/menu-variants.helper';
import { DropdownModule } from 'primeng/dropdown';
import { HorizontalMenuItemComponent } from '../shared/components/horizontal-menu-item/horizontal-menu-item.component';
import { ConnectionComponent } from '../admin-space/company-settings/company-integrations/connection/connection.component';
import { Connection } from '../shared/model/connection.model';
import { IconComponent } from '../shared/components/icon/icon.component';
import { ModalComponent } from '../shared/components/modal/modal.component';
import { InputSwitchModule } from 'primeng/inputswitch';
import { SelectButtonModule } from 'primeng/selectbutton';
import { SliderModule } from 'primeng/slider';
import { RadioButtonModule } from 'primeng/radiobutton';
import { SearchSystemComponent } from '../shared/components/search-system/search-system.component';
import { ToastModule } from 'primeng/toast';
import { AlertService, UserService } from '@harmanpa/ng-cae';
import { FormsModule, FormBuilder, ReactiveFormsModule } from '@angular/forms';
import { User } from 'generated-src';
import { OAuthPageComponent } from '../oauth-page/oauth-page.component';
import { TooltipModule } from 'primeng/tooltip';
import { KeyFilterModule } from 'primeng/keyfilter';
import { IncrementalFilterModule } from '../shared/helpers/incremental-filter.directive';
import { InfoMessageComponent } from '../shared/components/info-message/info-message.component';
import { TempCardComponent } from '../shared/components/temp-card/temp-card.component';
@Component({
  selector: 'inf-test',
  standalone: true,
  imports: [
    AvatarComponent,
    ButtonModule,
    CommonModule,
    ConnectionComponent,
    DividerModule,
    DropdownModule,
    FormsModule,
    HeaderComponent,
    HorizontalMenuItemComponent,
    IconComponent,
    IncrementalFilterModule,
    InfoMessageComponent,
    InputTextModule,
    InputSwitchModule,
    IntegrationBoxComponent,
    MenuItemComponent,
    ModalComponent,
    OAuthPageComponent,
    PricingComponent,
    RadioButtonModule,
    ReactiveFormsModule,
    SearchSystemComponent,
    SelectButtonModule,
    SidenavComponent,
    SliderModule,
    StatusBoxComponent,
    TableModule,
    TagComponent,
    TempCardComponent,
    TooltipModule,
    ToastModule,
    KeyFilterModule,
    UserMenuComponent,

  ],
  templateUrl: './test.component.html',
  providers: [MessageService, AlertService],
})
export class TestComponent {
  vector3Regex = /[\.0-9]+,[\.0-9]+,[\.0-9]+/;
  vectorHint = '';
  connection: Connection = {
    description: 'Brief description of why this connection exists',
    image: '../../../../assets/images/svg/onshape.svg',
    name: 'App Name',
  };
  dropdownOptions: { name: string; code: string }[] = [
    { name: 'New York', code: 'NY' },
    { name: 'Rome', code: 'RM' },
    { name: 'London', code: 'LDN' },
    { name: 'Istanbul', code: 'IST' },
    { name: 'Paris', code: 'PRS' },
  ];
  dropdownValue: { name: string; code: string } | undefined;
  dropdownValue2: { name: string; code: string } | undefined = {
    name: 'Paris',
    code: 'PRS',
  };
  inProgress: boolean = false;
  inviteForm = this.fb.group({
    email1: [{ value: '', disabled: this.inProgress }],
    email2: [{ value: '', disabled: this.inProgress }],
    email3: [{ value: '', disabled: this.inProgress }],
  });
  horizontalItems: MenuItem[] = [
    {
      label: 'Overview',
      icon: 'pi pi-home',
      routerLink: '/test',
    },
    {
      label: 'Not Overview',
      icon: 'pi pi-home',
      routerLink: '/products',
    },
    {
      label: 'Disabled',
      icon: 'pi pi-home',
      routerLink: '/products',
      disabled: true,
    },
  ];
  inputValue: string = '';
  item: MenuItem = {
    label: 'New',
    icon: 'pi pi-fw pi-plus',
    items: [
      {
        label: 'Sublevel 1',
        icon: 'pi pi-fw pi-moon',
        disabled: true,
      },
    ],
  };
  items: MenuItem[] = [
    {
      label: 'New',
      icon: 'pi pi-fw pi-plus',
      items: [
        {
          label: 'Sublevel 1',
          icon: 'pi pi-fw pi-moon',
          items: [{ label: 'Sublevel 2', icon: 'pi pi-fw pi-moon' }],
        },
      ],
    },
    { label: 'Open', icon: 'pi pi-fw pi-download' },
    { label: 'Undo', icon: 'pi pi-fw pi-refresh' },
  ];
  isCookiesModalOpen: boolean = false;
  isModalOpen: boolean = false;
  isModalOpenWS: boolean = false;
  isModalOpenTM: boolean = false;
  radioValue: string = 'Chicago';
  sidenav: MenuGroup[] = MenuVariants.MAIN_MENU;
  selectButtonOptions: string[] = ['Option 1', 'Option 2', 'Option 3'];
  selectButtonValue: string = 'Option 1';
  slider1: number = 50;
  slider2: number[] = [20, 80];
  tableValues = [
    {
      name: 'Product Name',
      type: 'Modular',
      status: 'info',
      country: 'Algeria',
      configurations: 24617,
    },
    {
      name: 'Product Name',
      type: 'Parametric',
      status: 'danger',
      country: 'Egypt',
      configurations: 82490,
    },
    {
      name: 'Product Name',
      type: 'Mod. + Param.',
      status: 'warning',
      country: 'Panama',
      configurations: 28334,
    },
    {
      name: 'Product Name',
      type: 'Parts/Component',
      status: 'success',
      country: 'Slovenia',
      configurations: 88521,
    },
  ];
  testValue: string = '';
  user: User = {};
  name: string = '';
  public emails: string[] = [];
  public display = false;
  showing: boolean=false;

  constructor(
    private messageService: MessageService,
    private alertService: AlertService,
    private fb: FormBuilder,
    private userService: UserService
  ) {}

  ngOnInit():void {
    this.userService.me().subscribe((res: {}) => {
      this.user = res;
    });
  }

  openModal(): void {
    this.isModalOpen = true;
  }
  openModalWS(): void {
    this.isModalOpenWS = true;
  }
  openModalTM(): void {
    this.isModalOpenTM = true;
  }

  openCookiesModal(): void {
    this.isCookiesModalOpen = true;
  }

  showToast(severity: string, detail: string) :void {
    this.messageService.add({
      severity: severity,
      summary: severity,
      detail: detail,
      life: 9999999999,
      sticky: true,
    });
  }

  assingName(name: string):void{
    // this.userService.
    console.log(name);
  }

  inviteTeam() :void{
    this.inProgress = true;
    const emailList = this.inviteForm.value;
    this.userService.inviteUsers(Object.values(emailList).join('; ')).subscribe({
        next: (newUsers: User[]) => {
            this.inProgress = false;
            this.display = false;
            this.emails = [];
            this.alertService.success('Invited ' + newUsers.length + ' new users');
        },
        error: (err: {}) => {
            this.inProgress = false;
            this.display = false;
            this.emails = [];
            this.alertService.error(err);
        }
    });
  }

  showinfmsg():void{
    this.showing = true;
        // this.messageService.add({
          // key: 'customToast', // Use a unique key to identify this toast
          // severity: 'custom', // Use a custom severity to style the toast
          // summary: 'Custom Toast', // Summary is not used for custom toasts
          // detail: '', // Detail is not used for custom toasts
          // life: 3000, // Duration of the toast in milliseconds
          // closable: true, // Whether the toast can be closed with the close button
          // data: { template: './info-message.component.html' } // Pass the custom template to the toast
        // });
      }
  
}
