import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonModule } from 'primeng/button';
import { RippleModule } from 'primeng/ripple';
import { StyleClassModule } from 'primeng/styleclass';
import assembly4631 from '../../../assets/images/json/Rak4631/RAK4631-V2.STEP.json';
import { BasicMaterialStyling, SceneDirective } from '@harmanpa/ng-cae';
import { SimpleSceneComponent } from '@harmanpa/ng-cae';
import { AssemblyComponent } from '@harmanpa/ng-cae';
import { Color, TextureLoader } from "three";
import { LandingLogoCloudsComponent } from '../logo-clouds/logo-clouds.component';

@Component({
  templateUrl: './feature.component.html',
  selector: 'inf-landing-feature',
  standalone: true,
  imports: [
    CommonModule,
    ButtonModule,
    RippleModule,
    StyleClassModule,
    AssemblyComponent,
    SimpleSceneComponent,
    SceneDirective,
    LandingLogoCloudsComponent
  ],
})
export class LandingFeatureComponent  {
  testAssembly = assembly4631;
  // materialStyling4631: BasicMaterialStyling;
  width = "500px";
  height = "300px"; 
  constructor() {}

  materialStyling4631: BasicMaterialStyling;

  ngOnInit(): void {
    const screwsColor = new Color('rgb(173,179,115)');
    const white = new Color('rgb(255, 255, 255)');
    const generalObjectsColor = new Color('rgb(181, 181, 181)');

    const loader = new TextureLoader();
    this.materialStyling4631 = {
        type: 'Basic',
        transparent: false,
        color: generalObjectsColor,  
        border: {
          type: "none"
        }, 
        otherMaps: {
            '0.19': {
                map: loader.load('/assets/images/json/Rak4631/materials/Image (5).png'),
                middleMap: loader.load('/assets/images/json/Rak4631/materials/Image (7).png'),
                color: white,
            },
            '0.20': {
                color: screwsColor
            },
            '0.21': {
                color: screwsColor
            },
            '0.22': {
                map: loader.load('/assets/images/json/Rak4631/materials/Image (3).png'),
                color: white,
            },
            '0.0': {
                map: loader.load('/assets/images/json/Rak4631/materials/Image.png'),
                bottomMap: loader.load('/assets/images/json/Rak4631/materials/Image (4).png'),
                middleMap: loader.load('/assets/images/json/Rak4631/materials/Image (1).png'),
                color: white,
            }
        }
    };
  }
}
